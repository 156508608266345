import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton } from '@mui/material';
import styled from '@emotion/styled';
import { theme } from '../../../theme';
export const Stepper = ({ newAmount, handleAddToCartChange }: any) => {
  return (
    <>
      <IconButtonStyled
        onClick={() => {
          handleAddToCartChange('minus');
        }}
      >
        <FontAwesomeIcon className="expand" icon={faMinus} />
      </IconButtonStyled>
      <span>{newAmount}</span>
      <IconButtonStyled
        reverse="rotate"
        onClick={() => {
          handleAddToCartChange('plus');
        }}
      >
        <FontAwesomeIcon className="expand" icon={faPlus} />
      </IconButtonStyled>
    </>
  );
};

const IconButtonStyled = styled(IconButton)<{ reverse?: string }>`
  position: absolute;
  right: ${({ reverse }) => reverse === 'rotate' && '0'};
  left: ${({ reverse }) => reverse !== 'rotate' && '0'};
  padding: 4px;
  transform: ${({ reverse }) => reverse === 'rotate' && 'rotateY(3.142rad)'};
  padding: 0;
  border-bottom-left-radius: 30px;
  border-top-left-radius: 30px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  min-height: 34px;
  min-width: 84px;
  color: #00000035;
`;
