export const theme = {
  colors: {
    primary: '#2596be',
    white: '#ffffff',
    black: '#000000',
    blueLight: '#046F7F',
    buttonActive: '#E6F4F5',
    grey: '#d3d3d3',
    greyDark: '#58595A',
    red: '#d1174b',
    yellow: '#fbdb14',
    orange: '#ffad1e',
    blueGradient:
      'linear-gradient(90deg, rgba(145,222,229,1) 0%, rgba(173,233,212,1) 78%)',
  },
};
