import React from 'react';

import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useConfiguration } from '../../hooks/useConfiguration';
import { SearchInputContainer, SearchInputStyled, SearchIcon } from './styles';
import { PiMagnifyingGlassBold } from 'react-icons/pi';
import { FaArrowLeftLong } from 'react-icons/fa6';
import { Nav, NavItems, NavContainer } from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { useNavigation } from './useNavigation';
import { useQuery } from '@apollo/client';
import { GET_LOCALIZED, GET_PRODUCT_CATEGORY_BY_TERM } from '../../queries';
import { theme } from '../../theme/index';
import styled from '@emotion/styled';

export const Navigation = () => {
  const {
    filteredSuggestions,
    handleSearchSuggestions,
    handleSuggestionClick,
    suggestionsVisible,
    searchKeyword,
  } = useNavigation();

  const { storeId = '', harvestId = '', termId } = useParams();

  const location = useLocation();
  const navigate = useNavigate();

  const { data: nameData, loading: nameLoading } = useQuery(
    GET_PRODUCT_CATEGORY_BY_TERM({ termId }),
    {
      skip: !termId,
    }
  );

  const name = nameData?.getEPogTermListing?.edges[0]?.node?.Name;

  const { Logo, Currency, LanguageVersion } = useConfiguration({
    storeId,
    harvestId,
  });

  const { data: translation } = useQuery(GET_LOCALIZED, {
    variables: {
      defaultLanguage: LanguageVersion,
    },
  });

  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
  const [prevLocation, setPrevLocation] = React.useState(
    window.location.pathname
  );
  const [currentLocation, setCurrentLocation] = React.useState(
    window.location.pathname
  );

  const handleLocationChange = (newLocation: any) => {
    setPrevLocation(currentLocation);
    setCurrentLocation(newLocation);
  };

  React.useEffect(() => {
    handleLocationChange(window.location.pathname);
    window.scrollTo(0, 0);
  }, [location]);

  React.useEffect(() => {
    setIsDrawerOpen(false);
  }, [location]);
  const currency = Currency ? Currency : '$';

  const url = location.pathname;
  const slashCount = url.split('/').length - 1;

  const handleGoToPreviousScreen = () => {
    navigate(-1);
  };

  return (
    <NavContainer>
      <Nav>
        <StyledPreviousScreenButtonWrapper>
          <StyledPreviousScreenButton onClick={handleGoToPreviousScreen} />
        </StyledPreviousScreenButtonWrapper>

        <NavItems>
          <div className="search-input">
            <SearchInputContainer>
              <>
                <SearchIcon>
                  <PiMagnifyingGlassBold size="20" color={theme.amazon.black} />
                </SearchIcon>
                <SearchInputStyled
                  type="text"
                  placeholder={(translation?.getLocalization?.Search || 'Search')+" Amazon.com"}
                  value={name}
                  onChange={(e) => handleSearchSuggestions(e.target.value)}
                />
              </>
            </SearchInputContainer>

            {suggestionsVisible && filteredSuggestions?.length ? (
              <div className="suggestions">
                {filteredSuggestions?.length > 0 &&
                  filteredSuggestions.map(({ key, id }: any, index: number) => (
                    <div
                      className="suggestion"
                      key={id}
                      onClick={() => handleSuggestionClick(id, key)}
                    >
                      <span>{key}</span>
                      <FontAwesomeIcon icon={faArrowUp} />
                    </div>
                  ))}
              </div>
            ) : (
              ''
            )}
          </div>
        </NavItems>
      </Nav>
    </NavContainer>
  );
};

const StyledPreviousScreenButtonWrapper = styled.div`
  width: 30px;
  justify-content: center;
  padding-left: 12px;
  user-select: none;
`;

const StyledPreviousScreenButton = styled(FaArrowLeftLong)`
  color: ${theme.amazon.black};
  font-size: 16px;
`;
