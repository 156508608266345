import React from 'react';
import { Chip } from '../Chip';
import styled from '@emotion/styled';
interface BrandChipsProps {
  brands: string[];
  handleBrandClick: (brand: string) => void;
  isChecked: (brand: string) => boolean;
}
export const BrandChips: React.FC<BrandChipsProps> = ({
  brands,
  handleBrandClick,
  isChecked,
}) => {
  const halfIndex = Math.ceil(brands.length / 2);
  const firstRowBrands = brands.slice(0, halfIndex);
  const secondRowBrands = brands.slice(halfIndex);

  return (
    <ChipsContainer>
      <ChipRow>
        {firstRowBrands.map((brand) => (
          <Chip
            key={brand}
            title={brand}
            onClick={() => handleBrandClick(brand)}
            colors={isChecked(brand) ? 'dark' : 'light'}
            isExpand={false}
            background="#ffffff"
          />
        ))}
      </ChipRow>
      <ChipRow>
        {secondRowBrands.map((brand) => (
          <Chip
            key={brand}
            title={brand}
            onClick={() => handleBrandClick(brand)}
            colors={isChecked(brand) ? 'dark' : 'light'}
            isExpand={false}
            background="#ffffff"
          />
        ))}
      </ChipRow>
    </ChipsContainer>
  );
};
const ChipsContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 100px;
`;
const ChipRow = styled.div`
  display: flex;
  gap: 4px;
  flex-wrap: nowrap;
  white-space: nowrap;
  align-items: center;
  min-height: 35px;
`;
