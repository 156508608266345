import { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { ThemeProvider } from '@emotion/react';
import { GrHomeRounded } from 'react-icons/gr';
import { IoPersonOutline } from 'react-icons/io5';
import { BsCart2 } from 'react-icons/bs';
import { AiOutlineMenu } from 'react-icons/ai';
import { theme } from '../../theme/index';
import { useAppContext } from '../../context';
import { useLocation, useNavigate } from 'react-router-dom';
import { BottomNavigationStarField } from './bottomNavigation/BottomNavigationStarField';

interface NavigationProps {
  navigation: (path: string) => void;
  storeId: any;
  harvestId: any;
}

export const BottomNavigationBar = ({
  navigation,
  storeId,
  harvestId,
}: NavigationProps) => {
  const [clickedIcon, setClickedIcon] = useState<string | null>('home');
  const [indicatorLeft, setIndicatorLeft] = useState(0);
  const [showStars, setShowStars] = useState(false);

  const prevSummedAmount = useRef(0);
  const navigate = useNavigate();
  const location = useLocation();
  const {
    value: { cart },
  } = useAppContext();
  const buttonRefs = useRef<any>([]);
  buttonRefs.current = [];

  const addToRefs = (el: any) => {
    if (el && !buttonRefs.current.includes(el)) {
      buttonRefs.current.push(el);
    }
  };

  const iconNames = ['home', 'profile', 'cart', 'menu'];

  const setIndicatorPosition = (index: number) => {
    if (index === 0 || index === 2) {
      const button = buttonRefs.current[index];
      if (button) {
        const rect = button.getBoundingClientRect();
        const left = rect.left + rect.width / 2 - 25;
        setIndicatorLeft(left);
      }
    }
  };

  const handleNavigation = (iconName: string, index: number) => {
    if (iconName === 'home' || iconName === 'cart') {
      setClickedIcon((prevIcon) => (prevIcon === iconName ? null : iconName));
      setIndicatorPosition(index);
    }
    if (iconName === 'home') {
      navigation(`/${storeId}/${harvestId}`);
    } else if (iconName === 'cart') {
      navigate(`/${storeId}/${harvestId}/cart`);
    }
  };

  const getTotalQuantity = () => {
    return cart.reduce((total: any, item: any) => total + item.amount, 0);
  };

  useEffect(() => {
    const currentPath = location.pathname;
    const isCartPage = currentPath.includes('/cart');
    const newClickedIcon = isCartPage ? 'cart' : 'home';
    setClickedIcon(newClickedIcon);

    const activeIndex = iconNames.findIndex((name) => name === newClickedIcon);
    setIndicatorPosition(activeIndex);
    const currentSummedAmount = cart.reduce(
      (total: any, item: any) => total + item.amount,
      0
    );
    if (cart.length > 0 && currentSummedAmount !== prevSummedAmount.current) {
      setShowStars(true);
      setTimeout(() => {
        setShowStars(false);
      }, 1600);
    }
    prevSummedAmount.current = currentSummedAmount;
  }, [location, cart]);

  return (
    <ThemeProvider theme={theme}>
      <BottomNavigationBarWrapper>
        <IconButton ref={addToRefs} onClick={() => handleNavigation('home', 0)}>
          <IconWrapper active={clickedIcon === 'home'}>
            <GrHomeRounded />
          </IconWrapper>
        </IconButton>
        <IconButton
          ref={addToRefs}
          onClick={() => handleNavigation('profile', 1)}
        >
          <IconWrapper active={false}>
            <IoPersonOutline />
          </IconWrapper>
        </IconButton>
        <IconButton ref={addToRefs} onClick={() => handleNavigation('cart', 2)}>
          <IconWrapper active={clickedIcon === 'cart'}>
            {getTotalQuantity()}

            <BsCart2 />
          </IconWrapper>
        </IconButton>
        <IconButton ref={addToRefs} onClick={() => handleNavigation('menu', 3)}>
          <IconWrapper active={false}>
            <AiOutlineMenu />
          </IconWrapper>
        </IconButton>
        <Indicator left={indicatorLeft} />
      </BottomNavigationBarWrapper>
    </ThemeProvider>
  );
};

const IconButton = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  text-align: center;
  position: relative;
  -webkit-tap-highlight-color: transparent;
`;

const IconWrapper = styled.div<{ active: boolean }>`
  font-size: 20px;
  color: ${({ active }) =>
    active ? `${theme.amazon.primary}` : `${theme.amazon.black}`};
  display: flex;
  justify-content: center;
  align-items: center;

  & > div {
    position: absolute;
    top: 0;
    left: 46px;
    z-index: 9999;
    background-color: ${theme.amazon.white};
    width: 8px;
    justify-content: center;
    & > div {
      font-family: Montserrat;
      font-weight: 600;
      font-size: 12px;
    }
  }
`;

const BottomNavigationBarWrapper = styled.div<any>`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 70px;
  background-color: ${theme.amazon.white};
  box-shadow: 0px -5px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
  padding-bottom: 10px;
`;

const Indicator = styled.div<{ left: number }>`
  position: absolute;
  top: 0;
  left: ${(props) => props.left}px;
  width: 50px;
  height: 5px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: ${theme.amazon.primary};
  transition: left 0.1s ease;
`;
