import styled from '@emotion/styled';
import { css, keyframes } from '@emotion/react';
import { theme } from '../../theme';
const fadeOutAnimation = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-5px);
  }
`;
export const FiltersStyled = styled('div')<{fadeOut: any}>`
  padding: 10px 0 8px;
  align-items: center;
  overflow-x: auto;
  animation: ${props => props.fadeOut ? css`${fadeOutAnimation} 0.4s forwards` : 'none'};
`;

export const PopularFiltersStyled = styled.div<{
  isLessThanSixItems?: boolean;
}>`
  display: flex;
  gap: 24px;
  overflow-x: auto;

  .filter {
    text-align: center;

    &:first-of-type {
      margin-left: auto;
    }
    &:last-of-type {
      margin-right: auto;
    }

    img {
      height: auto;
      max-height: 168px;
      width: 168px;
      object-fit: contain;
    }

    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 15px;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: center;
      white-space: normal;
      height: 28px;
    }
  }
  `;
  export const Line = styled.div `
  border-left: 1px solid ${theme.amazon.grey};
  margin-right: 10px;
`

export const Header = styled.button`
  max-width: 181px;
  font-weight: 600;
  font-size: 14px;
  line-height: 15px;
  color: ${theme.amazon.primary};
  background-color: ${theme.amazon.white};
  border: 1px solid ${theme.amazon.grey};
  border-radius: 5px;
  cursor: pointer;
  padding: 6px 16px;
  margin-top: 5px;
  margin-right: 10px;
`;

export const ChipsContainer = styled.div`
  display: flex;
`;