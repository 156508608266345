import styled from '@emotion/styled';
import { ModalTypes } from '../Modals/types';
import { theme } from '../../theme';

export const FiltersMenuStyled = styled.div<{
  modalType: ModalTypes;
  isInlineFilters: boolean;
}>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  font-family: 'San Francisco';
  min-height: ${({ isInlineFilters }) => (isInlineFilters ? '92vh' : '92vh')};

  .filters {
    flex: 1;
    .filter-container{
      border-bottom: 1px solid ${theme.amazon.grey};
    }
    .filter {
      position: relative;
      z-index: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px;
      span {
        font-family: 'San Francisco';
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: ${theme.amazon.black};
        cursor: pointer;
      }
      svg {
        font-size: 12px;
        cursor: pointer;
      }
    }
  }

  .filter-header {
    position: sticky;
    background-color: ${theme.amazon.white};
    z-index: 2;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid ${theme.amazon.grey};
    padding: 10px 12px;
    & > span {
      font-weight: 600;
      font-size: 20px;
      line-height: 22px;
    }
    & > svg {
      font-size: 16px;
      font-weight: 300;
      cursor: pointer;
    }
  }

  .filter-footer {
    position: ${({ isInlineFilters }) =>
      isInlineFilters ? 'initial' : 'sticky'};
    z-index: 2;
    background-color: ${theme.amazon.white};
    bottom: 0;
    left: 0;
    display: flex;
    width: 100%;
    padding: 10px;
    border-bottom: 1px solid ${theme.amazon.grey};
    border-top: 1px solid ${theme.amazon.grey};
    .button-footer{
      display: flex;
      justify-content: space-between;
      width: 100%;
      & > button {
        font-size: 14px;
        font-weight: 300;
        font-family: 'San Francisco';
        line-height: 22px;
        padding: 6px;
        text-transform: capitalize;
        width: 45%;
      }
    }
    @media (max-width: 767px) {
      & > button:first-of-type {
        margin-bottom: 10px;
      }
    }
  }

  .reset-button {
    background-color: ${theme.amazon.white};
    color: ${theme.amazon.primary};
    border: 1px solid ${theme.amazon.primary};
    margin-right: 10px;
  }

  .result-button {
    background-color: ${theme.amazon.primary};
    color: ${theme.amazon.white};
    margin-left: auto; 
  }
`;

