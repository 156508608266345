import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useParams, useNavigate } from 'react-router-dom';
import { useConfiguration } from '../../hooks/useConfiguration';
import { IProduct } from '../../types';
import { ProductCard } from '../ProductCard';
import { useAppContext } from '../../context';
import {
  normalizeNameForUrl,
  contentSquereCustomTag,
  artificialPageview,
} from '../../helpers';
import { actions } from '../../context/actions';
import { theme } from '../../theme';
import { Pagination } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { imgSrcByEnv } from '../../../config/imgSrcByEnv';
import { TCurrency } from '../../types/config';
import { AdvBanner } from '../AdvBanner';
import { useQuery } from '@apollo/client';
import { GET_LOCALIZED } from '../../queries';

export const ProductCardsList = ({
  products,
  name,
  promoBannerData,
}: {
  products: IProduct[];
  name: string;
  promoBannerData?: {
    TermBannerImage: { fullpath: string };
    TermBannerProducts: IProduct[];
    TermBannerSponsoredBy: string;
    TermBannerText: string;
    AddProductButton?: string;
    Currency: TCurrency;
  };
}) => {
  const {
    storeId = '',
    categoryId,
    termId,
    pageNr,
    harvestId = '',
  } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const {
    dispatch,
    value: { isInlineFilters },
  } = useAppContext();
  const { Columns, Rows, LanguageVersion } = useConfiguration({
    storeId,
    harvestId,
  });
  const { data: translation } = useQuery(GET_LOCALIZED, {
    variables: {
      defaultLanguage: LanguageVersion,
    },
  });
  const currentPage = Number(pageNr) || 1;
  const [productLength, setProductLength] = useState(0);
  // let itemsPerPage = Columns === 3 ? 9 : 16;
  let itemsPerPage = 16;

  // if (Rows && Columns) {
  //   itemsPerPage = Columns * Rows;
  // }
  const handlePaginationChange = (e: any, value: any) => {
    const currentScrollPosition = window.scrollY;
    navigate(
      categoryId
        ? `/${storeId}/${harvestId}/category/${categoryId}/${value}`
        : `/${storeId}/${harvestId}/search/${termId}/${value}`,
      {
        state: { scroll: currentScrollPosition },
        replace: true,
      }
    );
  };
  const currentProductList = products.filter(
    (item: IProduct, index: number) => {
      if (
        index >= itemsPerPage * (currentPage - 1) &&
        index < itemsPerPage * currentPage
      )
        return item;
    }
  );
  useEffect(() => {
    if (location.state?.scroll) {
      window.scrollTo(0, location.state.scroll);
    }
  }, [location.state?.scroll]);

  useEffect(() => {
    const newLength = products.filter((item) => item).length;
    if (productLength !== newLength) {
      setProductLength(newLength);
      dispatch({ type: actions.SET_PRODUCT_LENGTH, payload: newLength });
    }
  }, [products]);

  useEffect(() => {
    if (window.location.pathname.includes('category')) {
      contentSquereCustomTag(
        1,
        'Path',
        window.location.pathname + window.location.hash.replace('#', '?__'),
        ''
      );
      contentSquereCustomTag(2, 'pageType', 'Category', '');
      artificialPageview(
        window.location.pathname + window.location.hash.replace('#', '?__')
      );
    }
    if (window.location.pathname.includes('search')) {
      contentSquereCustomTag(
        1,
        'Path',
        window.location.pathname + window.location.hash.replace('#', '?__'),
        ''
      );
      contentSquereCustomTag(2, 'pageType', 'SERP', '');
      artificialPageview(
        window.location.pathname + window.location.hash.replace('#', '?__')
      );
    }
  }, [name]);
  return (
    <ProductsContainerStyled
      Columns={Columns}
      isInlineFilters={isInlineFilters}
      count={productLength}
    >
      <TitleStyled>
        {translation?.getLocalizationAmzWm?.CheckEachProductPage || 'Check each product page for other buying options.'}
      </TitleStyled>
      <ProductListStyled>
        {products.length > 0 &&
          currentProductList.map((item: IProduct, index: number) => {
            const isPromoBanner = item?.Video?.title === '__promoBannerPlaceholder__';
            let itemIndex = index;
            const price = item?.Price ? item?.Price : item?.RegularPrice;
            const brand = item?.Brand ? item?.Brand : '';
            if (isPromoBanner && !products[index - 1]?.fullpath?.includes("Products")) // prev video
              itemIndex = index - 1;
            if (isPromoBanner && !products[index + 1]?.fullpath?.includes("Products")) // next video
              itemIndex = index + 1;
            const position = itemsPerPage * (currentPage - 1) + itemIndex + 1;
            let promoCode;
            if (item.URLPromoCode != null) {
              promoCode = '__promoCode-' + item.URLPromoCode;
            } else {
              promoCode = '__promoCode-';
            }
            let newColumns = 4;
            let cssClass = '';
            if (Columns !== undefined) { newColumns = Columns; }
            if (position === 1 || position % newColumns === 1) { cssClass = 'fullsize'; }
            if (isPromoBanner) {
              return promoBannerData?.TermBannerProducts && <AdvBanner {...promoBannerData} />
            } else if (!item?.fullpath?.includes("Products")) {
              return (
                <div className={'video '+cssClass}>
                  <video src={`${imgSrcByEnv()}${item?.Video?.data.fullpath}`} controls autoPlay muted loop/>
                </div>
              )
            } else {
            return (
              item && (
                <ProductCard
                  variant="horizontal"
                  key={item.id}
                  product={item}
                  url={
                    categoryId
                      ? `/${storeId}/${harvestId}/ip/${categoryId}/${
                          item?.id
                        }__${brand}__${normalizeNameForUrl(
                          item?.Name
                        )}}_${price}_${position}${promoCode}`
                      : `/${storeId}/${harvestId}/ips/${
                          item?.id
                        }__${brand}__${normalizeNameForUrl(
                          item?.Name
                        )}_${price}_${position}${promoCode}`
                  }
                  onClick={
                    categoryId
                      ? `/${storeId}/${harvestId}/ip/${categoryId}/${
                          item?.id
                        }__${brand}__${normalizeNameForUrl(
                          item?.Name
                        )}}_${price}_${position}${promoCode}`
                      : `/${storeId}/${harvestId}/ips/${
                          item?.id
                        }__${brand}__${normalizeNameForUrl(
                          item?.Name
                        )}_${price}_${position}${promoCode}`
                  }
                />
              )
            );
          }
          })}
      </ProductListStyled>
      {productLength && (
        <PaginationStyled
          shape="rounded"
          count={Math.ceil(productLength / itemsPerPage)}
          page={currentPage}
          onChange={handlePaginationChange}
          hidePrevButton={currentPage === 1}
          hideNextButton={
            currentPage === Math.ceil(productLength / itemsPerPage)
          }
        />
      )}
    </ProductsContainerStyled>
  );
};
const ProductsContainerStyled = styled.div<{
  Columns?: number;
  isInlineFilters: boolean;
  count?: number;
}>`
  padding-bottom: 40px;
`;

const TitleStyled = styled.p`
  margin-right: 9px;
  margin-left: 9px;
  font-size: 11px;
  color: ${theme.amazon.greyDark};
`;
const ProductListStyled = styled.div`
  display: flex;
  margin-top: 10px;
  margin-left: 9px;
  margin-right: 9px;
  padding-bottom: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;

  .fullsize {
    width: 100%;

    grid-column: 1 / span 4;

     @media (max-width: 1140px) {
      grid-column: auto;
    }
    @media (max-width: 768px) {
      grid-column: 1 / span 2;
    }
    @media (max-width: 550px) {
      grid-column: 1 / span 1;
    }
  }
    
  .video{
    margin-left:auto;
    margin-right:auto;
    video {
      width: 100%;
      padding: 26px 0 0 0;
    }
  }
`;
const PaginationStyled = styled(Pagination)`
  display: flex;
  justify-content: center;
`;
