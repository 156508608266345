import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

export const NavContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;

  background: linear-gradient(
    90deg,
    rgba(145, 222, 229, 1) 0%,
    rgba(173, 233, 212, 1) 78%
  );
  z-index: 1000;
  @media (max-width: 1128px) {
    /* padding: 0 15px; */
  }
  color: ${(props: any) =>
    props.theme.navBg === '#FFFFFF' ? '#000000' : '#FFFFFF'};

  a {
    color: ${(props: any) =>
      props.theme.navBg === '#FFFFFF' ? '#000000' : '#FFFFFF'};
    text-decoration: none !important;
  }

  background: ${(props: any) => {
    if (props.theme.navBg === '#232F3E') {
      return 'linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #232F3E';
    } else {
      return props.theme.navBg;
    }
  }};
`;

export const LinkStyled = styled(Link)`
  text-decoration: none;
  color: #000;
  .MuiTypography-root {
    font-size: 16px;
    padding: 0 10px;
  }
`;

export const Nav = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* height: 80px; */
  padding: 10px 0px;
  max-width: 1128px;
  margin: 0 auto;
  background: linear-gradient(
    90deg,
    rgba(145, 222, 229, 1) 0%,
    rgba(173, 233, 212, 1) 78%
  );

  & > img {
    max-width: 130px;
    max-height: 60px;
    cursor: pointer;
    display: block;
    @media (max-width: 768px) {
      display: none;
    }
  }
  .menu-icon {
    display: block;
    @media (min-width: 768px) {
      display: none;
    }

    font-size: 24px;
  }
`;

export const SgnItem = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    display: none;
  }

  svg {
    margin-right: 10px;
    fill: #fff;
    stroke: ${(props: any) =>
      props?.theme?.navFontColor ? props?.theme?.navFontColor : '#FFFFFF'};
  }
  > a {
    color: ${(props: any) =>
      props?.theme?.navFontColor ? props?.theme?.navFontColor : '#FFFFFF'};
  }
`;

export const Cart = styled.div`
  display: flex;
  align-items: center;
  a {
    text-align: end;
    min-width: 48px;
    @media (max-width: 768px) {
      min-width: 38px;
    }
    color: ${(props: any) =>
      props?.theme?.navFontColor ? props?.theme?.navFontColor : '#FFFFFF'};
  }
  svg {
    margin-right: 10px;
    fill: #fff;
    stroke: ${(props: any) =>
      props?.theme?.navFontColor ? props?.theme?.navFontColor : '#FFFFFF'};
  }
`;

export const NavItems = styled.div`
  flex: 1;
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 12px;
  display: flex;
  gap: 20px;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: 768px) {
    gap: 0;
    justify-content: space-between;
  }

  & > a {
    display: block;

    color: ${(props: any) =>
      props?.theme?.navFontColor ? props?.theme?.navFontColor : '#FFFFFF'};

    @media (max-width: 768px) {
      display: none;
    }
  }

  .search-input {
    position: relative;
    width: 100%;

    @media (min-width: 1100px) {
      min-width: 552px;
    }
    @media (max-width: 768px) {
      margin: 0 10px;
    }
  }

  .suggestions {
    position: absolute;
    z-index: 1000;
    top: 45px;
    width: 100%;
    background: ${({ theme }: any) => theme?.navBg};
    border: ${({ theme }: any) => `1px solid ${theme?.navBg}`};
    border-top: none;
    padding: 15px;
    .suggestion {
      display: flex;
      justify-content: space-between;
      padding: 10px 0;
      cursor: pointer;
    }
  }
`;

export const SearchInputContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
`;

export const SearchInputStyled = styled.input`
  width: 100%;
  padding: 10px;
  padding-left: 40px;
  border: 1px solid #ccc;
  border-radius: 8px;
  height: 45px;
  font-size: 15px;
  font-weight: 600;
  font-family: Arial, sans-serif;
  pointer-events: none;

  ::placeholder {
    color: #d3d3d3;
  }
`;

export const SearchSuggestions = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background: #fff;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 5px 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

export const SearchSuggestionItem = styled.div`
  padding: 10px;
  cursor: pointer;

  &:hover {
    background-color: #f5f5f5;
  }
`;

export const SuggestionIcon = styled.div`
  margin-right: 5px;
`;

export const ClearSearchButton = styled.button`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  color: #666;
`;

export const SearchIcon = styled.div`
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  color: black;
`;

export const SearchRightIcon = styled.div`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  color: black;
`;

export const SearchRightIcon2 = styled.div`
  position: absolute;
  top: 50%;
  right: 50px;
  transform: translateY(-50%);
  color: black;
`;

export const AdditionalIcons = styled.div`
  display: flex;
  align-items: center;
`;
