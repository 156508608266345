import React, { useState, useEffect } from 'react';
import { IconButton, List, ListItemText } from '@mui/material';
import {
  HeaderTypography,
  SelectHeader,
  StyledListItemButton,
  StyledModalBox,
} from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useParams } from 'react-router-dom';
import { useConfiguration } from '../../hooks/useConfiguration';
import { useQuery } from '@apollo/client';
import { GET_LOCALIZED } from '../../queries';
interface QuantitySelectModalProps {
  newAmount: number;
  handleChange: (event: { target: { value: number } }) => void;
  handleClose: () => void;
  maxValue: number;
}
const QuantitySelectModal: React.FC<QuantitySelectModalProps> = ({
  newAmount,
  handleChange,
  handleClose,
  maxValue,
}) => {
  const { storeId='', harvestId='' } = useParams();
  const { LanguageVersion } = useConfiguration({
    storeId,
    harvestId,
  });
  const { data: translation } = useQuery(GET_LOCALIZED, {
    variables: {
      defaultLanguage: LanguageVersion,
    },
  });
  const [selectedAmount, setSelectedAmount] = useState<number>(newAmount);
  useEffect(() => {
    setSelectedAmount(newAmount);
  }, [newAmount]);

  const handleSelect = (value: number) => {
    setSelectedAmount(value);
    handleChange({ target: { value } });
    handleClose();
  };

  return (
    <StyledModalBox>
      <SelectHeader>
        <HeaderTypography id="quantity-select-modal-title">
          {translation?.getLocalizationAmzWm?.Quantity || "Quantity"}
        </HeaderTypography>
        <IconButton onClick={handleClose}>
          <FontAwesomeIcon icon={faTimes} />
        </IconButton>
      </SelectHeader>
      <List>
        {Array.from({ length: maxValue }, (_, i) => i + 1).map((value) => (
          <StyledListItemButton
            key={value}
            onClick={() => handleSelect(value)}
            selected={value === selectedAmount}
          >
            <ListItemText
              primary={value}
              primaryTypographyProps={{ fontSize: '14px' }}
            />
          </StyledListItemButton>
        ))}
      </List>
    </StyledModalBox>
  );
};

export default QuantitySelectModal;
