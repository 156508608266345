import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import Stack from '@mui/material/Stack';
import Rating from '@mui/material/Rating';
import { FilterModalCommonStyles } from '../commonStyles';
import { useAppContext } from '../../../context';
import { actions } from '../../../context/actions';
import { useParams } from 'react-router-dom';
import { useConfiguration } from '../../../hooks/useConfiguration';
import { GET_LOCALIZED } from '../../../queries';
import { useQuery } from '@apollo/client';
import { Chip } from '../../Chip';
import { theme } from '../../../theme/theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

export const RatingFilter = ({ buttonsOff }: any) => {
  const {
    value: { searchFilters },
    dispatch,
  } = useAppContext();
  const { storeId = '', harvestId = '' } = useParams();
  const { LanguageVersion } = useConfiguration({ storeId, harvestId });
  const { data: translation } = useQuery(GET_LOCALIZED, {
    variables: { defaultLanguage: LanguageVersion },
  });

  const ratingFilter = searchFilters.find(
    (filter: any) => filter.name === 'Rating'
  );
  const [selectedRatings, setSelectedRatings] = useState<number[]>(
    ratingFilter?.value || []
  );

  useEffect(() => {
    const currentFilter = searchFilters.find(
      (filter: any) => filter.name === 'Rating'
    );
    setSelectedRatings(currentFilter?.value || []);
  }, [searchFilters]);

  const handleSelect = (rating: number) => {
    const newSelected = selectedRatings.includes(rating)
      ? selectedRatings.filter((item) => item !== rating)
      : [...selectedRatings, rating];

    setSelectedRatings(newSelected);
    if (newSelected.length === 0) {
      handleClear();
    } else {
      handleSubmit(newSelected);
    }
  };

  const isChecked = (rating: number) => selectedRatings.includes(rating);

  const handleSubmit = (ratings: number[]) => {
    dispatch({
      type: actions.ADD_SEARCH_FILTER,
      payload: { name: 'Rating', value: ratings },
    });
  };

  const handleClear = () => {
    setSelectedRatings([]);
    dispatch({
      type: actions.REMOVE_SEARCH_FILTER,
      payload: { name: 'Rating', value: [] },
    });
  };

  return (
    <FilterModalCommonStyles buttonsOff={buttonsOff}>
      <ComponentStyled>
        <div className="chip-container">
          {[4, 3, 2, 1].map((star) => (
            <Chip
              key={star}
              rating={<RatingComponent rating={star} text={translation?.getLocalizationAmzWm?.AndUp} />}
              title=""
              onClick={() => handleSelect(star)}
              addinitonalIcon={
                isChecked(star) && <FontAwesomeIcon icon={faTimes} />
              }
              fontColor={isChecked(star) ? `${theme.colors.primary}` : ''}
              background={
                isChecked(star)
                  ? `${theme.colors.buttonActive}`
                  : `${theme.colors.white}`
              }
            />
          ))}
        </div>
      </ComponentStyled>
    </FilterModalCommonStyles>
  );
};

const ComponentStyled = styled.div`
  .chip-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 8px;
    max-width: 100%;
    margin-bottom: 16px;
  }
`;

const RatingComponent = ({
  rating,
  text,
}: {
  rating: number;
  text: string;
}) => {
  return (
    <RatingStyled spacing={1} className="stack">
      <Rating
        name="half-rating-read"
        defaultValue={rating}
        precision={0.5}
        readOnly
      />
      <div className="text">{text}</div>
    </RatingStyled>
  );
};

const RatingStyled = styled(Stack)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  .text {
    font-weight: 400;
    margin-left: 10px;
    margin-top: 0;
  }
`;
