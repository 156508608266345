import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';
import { theme } from '../../theme';

export const LoadingDotsAnimation = () => (
  <LoadingDots>
    <Dot />
    <Dot />
    <Dot />
  </LoadingDots>
);

const BounceAnimation = keyframes`
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
`;

const Dot = styled.div`
  background-color: ${theme.amazon.greyDark};
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin: 0 5px;
  animation: ${BounceAnimation} 1.4s infinite ease-in-out both;
  &:nth-of-type(1) {
    animation-delay: -0.32s;
  }
  &:nth-of-type(2) {
    animation-delay: -0.16s;
  }
`;

const LoadingDots = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
