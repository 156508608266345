import styled from '@emotion/styled';

export const CartRouteStyled = styled.div`
  .empty {
    padding: 100px;
    font-size: 22px;
    font-weight: 800;
  }

  & > p:nth-of-type(1) {
    margin: 64px 0 32px;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #000000;

    @media (max-width: 768px) {
      margin: 32px 0 16px;
    }

    & > span {
      font-weight: 400;
      font-size: 20px;
    }
  }

  .cart-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
    align-items: start;

    .cart-list {
      grid-column: 1 / span 2;
    }
    .cart-total {
      grid-column: 3 / span 1;
    }
    @media (max-width: 768px) {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      gap: 16px;
    }
  }

  .similar-products {
    & > p {
      margin-top: 72px;
      margin-bottom: 24px;
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      color: #000000;

      @media (max-width: 768px) {
        margin: 16px 0;
      }
    }
  }
  .arrives {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .cart-list {
    & > div {
      box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
    }
    @media (max-width: 768px) {
      width: 100%;
    }
  }

  .product-details {
    display: flex;

    img {
      height: 71px;
      width: 71px;
      object-fit: contain;

      @media (max-width: 768px) {
        height: 100px;
        width: 100px;
      }
    }

    & > span {
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      color: #000000;
    }

    .name {
      flex: 1;
      display: flex;
      gap: 16px;

      @media (max-width: 550px) {
        display: block;
      }

      img {
        cursor: pointer;
      }
      & > div > p:nth-of-type(1) {
        max-width: 225px;
        max-height: 51px;
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        color: #000000;
        cursor: pointer;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        @media (max-width: 550px) {
          margin-top: 10px;
          max-width: initial;
        }
      }

      & > div > p:nth-of-type(2) {
        max-width: 63px;
        max-height: 14px;
        font-family: 'Open Sans';
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: #000000;
        margin-top: 8px;
      }
    }
  }
  .footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    @media (max-width: 370px) {
      flex-wrap: wrap;
    }

    & > button {
      margin-top: 6.5px;
      font-family: 'Montserrat';
      font-weight: 400;
      font-size: 14px;
      line-height: 15px;
      text-decoration-line: underline;
      color: #000000;
    }

    & > div {
      margin-top: 6.5px;
    }
  }
  .cart-total {
    width: 100%;
    background: #ffffff;
    /* padding: 30px; */
    position: relative;
    background: #ffffff;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
    border-radius: 10px;

    @media (max-width: 991px) {
      /* padding: 20px; */
    }

    .total-line {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      color: #000000;

      & > span:nth-of-type(2) {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #000000;
      }

      & > span:nth-of-type(1) > span {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #000000;
      }

      .taxes {
        margin-top: 29px;
      }

      .calculated {
        margin-top: 29px;
      }
    }

    & > hr {
      margin: 25px 0;
    }

    & > div > button {
      width: 100%;
      padding: 9px 16px;
      height: 44px;
      background: ${({ theme }: any) => theme.productAddToCartBg};
      border-radius: 3px;
      font-family: 'Montserrat';
      font-weight: 700;
      font-size: 17px;
      line-height: 22px;
      color: #ffffff;
    }
    .checkout-button-top {
      hr {
        margin: 25px 0;
      }
    }
    .checkout-button-bottom {
      hr {
        margin: 25px 0;
      }
    }

    /* .total-footer {
      margin-top: 29px;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      text-align: right;
      color: #000000;
    } */
  }

  .inner-content {
    /* padding: 30px; */
    margin-bottom: 24px;

    @media (max-width: 768px) {
      /* padding: 20px; */
    }

    & > div > hr {
      margin-bottom: 24px;
    }

    & > p {
      font-family: 'Open Sans';
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: #000000;
      margin-bottom: 24.5px;
    }
  }

  .additional-info {
    & > h1 {
      margin-top: 31.5px;
      margin-bottom: 8px;
      max-width: 162px;
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      color: #000000;
    }

    & > p:nth-of-type(1) {
      max-width: 205px;
      font-weight: 400;
      font-size: 14px;
      line-height: 15px;
      color: #000000;
    }

    & > span:nth-of-type(1) {
      margin-top: 8px;
      max-width: 207px;
      font-weight: 400;
      font-size: 14px;
      line-height: 15px;
      color: #000000;
    }

    & > span:nth-of-type(2) {
      max-width: 42px;
      font-weight: 400;
      font-size: 14px;
      line-height: 15px;
      text-decoration-line: underline;
      color: #000000;
      margin-left: 8px;
    }
  }

  .radio-container {
    margin-top: 16px;
    margin-bottom: 31px;
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;
    color: #000000;
  }

  .next-additional-info {
    & > div {
      display: flex;
      justify-content: space-between;
    }

    & > div:nth-of-type(1) > p {
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      color: #000000;
      margin-bottom: 8px;
    }

    & > div:nth-of-type(2) > p {
      font-weight: 400;
      font-size: 14px;
      line-height: 15px;
      color: #000000;
      margin-bottom: 32px;
    }
  }
`;

export const HideDetails = styled.div`
  display: flex;
  justify-content: space-between;

  & > p {
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #000000;
  }

  & > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  & > div > span {
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;
    color: #000000;
    margin-right: 10px;
  }
`;
