import React from 'react';

import styled from '@emotion/styled';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import { useAppContext } from '../../../context';
import { actions } from '../../../context/actions';
import { FilterModalCommonStyles } from '../commonStyles';
import { FilterModalButtons } from '../../FilterModalButtons';
import { useParams } from 'react-router-dom';
import { useConfiguration } from '../../../hooks/useConfiguration';
import { GET_LOCALIZED } from '../../../queries';
import { useQuery } from '@apollo/client';
import { Chip } from '../../Chip';
import { theme } from '../../../theme/theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
const sortingOptions = [
  { key: 'Alphabet:A>Z', label: 'AlphabetAZ' },
  { key: 'Alphabet:Z>A', label: 'AlphabetZA' },
  { key: 'Price:low>high', label: 'PriceLH' },
  { key: 'Price:high>low', label: 'PriceHL' },
  { key: 'Rating:low>high', label: 'RatingLH' },
  { key: 'Rating:high>low', label: 'RatingHL' },
];
export const Sorting = ({ buttonsOff }: any) => {
  const [value, setValue] = React.useState('');

  const { storeId = '', harvestId = '' } = useParams();

  const { LanguageVersion } = useConfiguration({ storeId, harvestId });

  const { data: translation } = useQuery(GET_LOCALIZED, {
    variables: {
      defaultLanguage: LanguageVersion,
    },
  });

  const {
    dispatch,
    value: { productsSorting },
  } = useAppContext();

  const handleSelect = (selectedValue: string) => {
    setValue(selectedValue === value ? '' : selectedValue);
    dispatch({
      type:
        selectedValue === value
          ? actions.REMOVE_PRODUCTS_SORTING
          : actions.ADD_PRODUCTS_SORTING,
      payload: selectedValue === value ? {} : { value: selectedValue },
    });
  };

  React.useEffect(() => {
    setValue(productsSorting && productsSorting[0]?.value);
  }, [productsSorting]);

  return (
    <FilterModalCommonStyles buttonsOff={buttonsOff}>
      <ComponentStyled>
        <div className="sort-chips">
          {sortingOptions.map((option) => (
            <Chip
              key={option.key}
              title={translation?.getLocalization[option.label] || option.label}
              onClick={() => handleSelect(option.key)}
              addinitonalIcon={
                value === option.key && <FontAwesomeIcon icon={faTimes} />
              }
              fontColor={value === option.key ? `${theme.colors.primary}` : ''}
              background={
                value === option.key
                  ? `${theme.colors.buttonActive}`
                  : `${theme.colors.white}`
              }
              colors={value === option.key ? 'white' : 'black'}
            />
          ))}
        </div>
      </ComponentStyled>
    </FilterModalCommonStyles>
  );
};

const ComponentStyled = styled.div`
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  h2 {
    font-size: 1.7rem;
    font-weight: 600;
  }
`;

const SortComponentStyled = styled.div`
  flex: 1;

  .sort-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 2rem;
    height: 30px;
    & > span {
      font-size: 1.7rem;
      font-weight: 500;
    }
    & > div {
      span:first-of-type {
        font-size: 1.5rem;
        font-weight: 400;
      }
      span:last-of-type {
        margin-left: 30px;
      }
    }
    .dash {
      font-size: 2rem;
    }
    .plus {
      font-size: 3rem;
    }
  }
  .sort-list-container {
    overflow: hidden;
  }
  .sort-list {
    margin-top: 5%;
    padding: 10px;

    .MuiFormControlLabel-label {
      font-size: 1.3rem;
    }
  }
`;
