import React from 'react';

import { useQuery } from '@apollo/client';
import { GET_PRODUCT_CATEGORY_BY_TERM } from '../../../queries';
import { useNavigate, useParams } from 'react-router-dom';

import { IProduct } from '../../../types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import FormGroup from '@mui/material/FormGroup';
import { FilterModalCommonStyles } from '../commonStyles';
import { CategoriesStyled } from './styles';
import { useCategoriesFilter } from './useCategoriesFilter';

import { useConfiguration } from '../../../hooks/useConfiguration';
import { GET_LOCALIZED } from '../../../queries';
import { Chip } from '../../Chip';
import { theme } from '../../../theme/theme';

export const CategoriesFilterModal = ({
  productsBeforeFiltering,
  buttonsOff,
}: any) => {
  const { searchBar, handleCheckboxes, checked } = useCategoriesFilter({
    productsBeforeFiltering,
  });

  const { storeId = '', termId, harvestId = '' } = useParams();

  const { LanguageVersion } = useConfiguration({ storeId, harvestId });

  const { data: translation } = useQuery(GET_LOCALIZED, {
    variables: {
      defaultLanguage: LanguageVersion,
    },
  });

  const { data, loading } = useQuery(
    GET_PRODUCT_CATEGORY_BY_TERM({
      termId: termId?.split('__')[0],
    })
  );

  const edges = data?.getEPogTermListing?.edges;

  const categories = edges
    .map((edge: any) => edge?.node?.CategoriesFilterElements)
    .flat();

  if (categories.length > 1) {
    return (
      <FilterModalCommonStyles buttonsOff={buttonsOff}>
        <CategoriesStyled>
          <div>
            <div className="checkboxes">
              <FormGroup>
                {categories &&
                  Object.values(categories).map((value, index: number) => {
                    return (
                      <Chip
                        key={index}
                        title={categories[index].CategoryName}
                        onClick={() =>
                          handleCheckboxes(categories[index].CategoryName)
                        }
                        addinitonalIcon={
                          checked[categories[index].CategoryName] && (
                            <FontAwesomeIcon icon={faTimes} />
                          )
                        }
                        fontColor={
                          checked[categories[index].CategoryName]
                            ? `${theme.colors.primary}`
                            : ''
                        }
                        background={
                          checked[categories[index].CategoryName]
                            ? `${theme.colors.buttonActive}`
                            : `${theme.colors.white}`
                        }
                      />
                    );
                  })}
              </FormGroup>
            </div>
          </div>
        </CategoriesStyled>
      </FilterModalCommonStyles>
    );
  } else {
    return (
      <FilterModalCommonStyles buttonsOff={buttonsOff}>
        <CategoriesStyled>
          <div>
            <div className="checkboxes">
              <FormGroup>
                {productsBeforeFiltering ? (
                  productsBeforeFiltering
                    .filter(
                      (product: IProduct, index: number, arr: IProduct[]) =>
                        product.SubCategory?.toLowerCase().includes(
                          searchBar.toLowerCase()
                        ) &&
                        index ===
                          arr.findIndex(
                            (item) => item.SubCategory === product.SubCategory
                          )
                    )
                    .map((product: IProduct, index: number) => (
                      <Chip
                        key={index}
                        title={product.SubCategory}
                        onClick={() => handleCheckboxes(product.SubCategory)}
                        isExpand={checked[product.SubCategory]}
                        addinitonalIcon={
                          checked[product.SubCategory] && (
                            <FontAwesomeIcon icon={faTimes} />
                          )
                        }
                        fontColor={
                          checked[product.SubCategory]
                            ? `${theme.colors.primary}`
                            : ''
                        }
                        background={
                          checked[product.SubCategory]
                            ? `${theme.colors.buttonActive}`
                            : `${theme.colors.white}`
                        }
                      />
                    ))
                ) : (
                  <div></div>
                )}
              </FormGroup>
            </div>
          </div>
        </CategoriesStyled>
      </FilterModalCommonStyles>
    );
  }
};
