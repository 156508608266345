import React from 'react';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import {
  GET_ALL_CATEGORY_PRODUCTS,
  GET_PRODUCT_CATEGORY_BY_TERM,
} from '../../queries';
import { ProductCardsList } from '../../components/ProductCardsList';

import { Code } from 'react-content-loader';

import styled from '@emotion/styled';
import { IProduct } from '../../types';
import { handleFiltering, handleSorting } from '../../helpers';
import { useAppContext } from '../../context';
import { AdvBanner } from '../../components/AdvBanner';
import { PopularFilters } from '../../components/PopularFilters';
import { FiltersMenu } from '../../components/FiltersMenu';
import { useConfiguration } from '../../hooks/useConfiguration';

export const CategoryRoute = () => {
  const { storeId = '', termId, harvestId = '', categoryId } = useParams();

  const { data, loading } = useQuery(
    GET_PRODUCT_CATEGORY_BY_TERM({
      termId: termId?.split('__')[0],
    })
  );

  const { data: productsData } = useQuery(GET_ALL_CATEGORY_PRODUCTS, {
    variables: {
      id: categoryId?.toString(),
    },
  });

  const { FilterVisibility, FilterPosition, AddProductButton, Currency } =
    useConfiguration({
      storeId,
      harvestId,
    });

  const {
    value: { productsSorting, searchFilters, isInlineFilters },
  } = useAppContext();

  if (!loading) {
    const edges = data?.getEPogTermListing?.edges;

    const categories =
      edges && edges?.map((edge: any) => edge?.node?.children).flat();

    const banner = edges && edges[0]?.node;

    const productsFromDb =
      edges
        ?.map((edge: any) => edge?.node?.Products)
        ?.flat()
        ?.filter((item: any) => item) || [];

    const handleProducts = (products: IProduct[]) => {
      const sortProducts =
        products && handleSorting({ products, productsSorting });
      const filterProducts =
        sortProducts &&
        handleFiltering({
          products: sortProducts,
          searchFilters,
        });

      return filterProducts;
    };

    const node = productsData?.getEPogTermListing?.edges[0]?.node;
    let arr: IProduct[] = [];

    const handleKey = (obj: IProduct, name: string) => {
      obj &&
        Object.entries(obj).forEach(([key, value]) => {
          if (key === 'Products' && value?.length > 0) {
            value.forEach((item: any) => arr.push(item));
          }
          if (key === 'children') {
            value.forEach((item: any) => handleKey(item, 'Products'));
          }
        });
    };
    handleKey(node, 'Products');

    const products = [
      ...new Map(
        [...arr, ...productsFromDb]?.map((obj) => [obj.id, obj])
      ).values(),
    ].filter((item) => item);

    const productsToRender = products && handleProducts(products);

    const brands: any = [
      ...new Set(products.map((product: IProduct) => product.Brand)),
    ];

    const name = data?.getEPogTermListing?.edges[0]?.node?.key;
    const currency = Currency ? Currency : '$';

    return (
      <RouteStyled>
        {banner?.TermBannerProducts && (
          <AdvBannerContainer>
            <AdvBanner
              TermBannerImage={banner?.TermBannerImage}
              TermBannerProducts={banner?.TermBannerProducts}
              TermBannerSponsoredBy={banner?.TermBannerSponsoredBy}
              TermBannerText={banner?.TermBannerText}
              Currency={currency}
              TermBannerVideo={banner?.TermBannerVideo?.data?.fullpath}
              TermBannerVideoPosition={banner?.TermBannerVideoPosition}
            />
          </AdvBannerContainer>
        )}

        <PopularFiltersStyled
          hasBanner={!!banner?.TermBannerProducts} // Pass a prop to indicate the presence of the banner
        >
          <PopularFilters
            products={productsToRender}
            productsBeforeFiltering={products}
            categories={categories}
            brands={brands}
          />
        </PopularFiltersStyled>

        <div className="container">
          {isInlineFilters &&
            FilterVisibility === 'Stick' &&
            FilterPosition === 'Left' && (
              <div className="filter-container">
                <div>
                  <FiltersMenu productsBeforeFiltering={products} />
                </div>
              </div>
            )}

          <ProductCardsList products={productsToRender} name={name} 
            promoBannerData={{
              TermBannerImage: banner?.TermBannerImage,
              TermBannerProducts: banner?.TermBannerProducts,
              TermBannerSponsoredBy: banner?.TermBannerSponsoredBy,
              TermBannerText: banner?.TermBannerText,
              AddProductButton: AddProductButton,
              Currency: currency,
            }} />

          {isInlineFilters &&
            FilterVisibility === 'Stick' &&
            FilterPosition === 'Right' && (
              <div className="filter-container">
                <div>
                  <FiltersMenu productsBeforeFiltering={products} />
                </div>
              </div>
            )}
        </div>
      </RouteStyled>
    );
  } else {
    return (
      <div style={{ marginTop: 24 }}>
        <Code />
      </div>
    );
  }
};

const RouteStyled = styled.div`
  video.promoBannerVideo{
    margin-top: 0;
    margin-bottom: 0;
  }
  .container {
    display: flex;
    margin-top: 20px;
    justify-content: center;
    flex-direction: column;
  }
  .filter-container {
    min-width: 420px;
    @media (max-width: 1000px) {
      min-width: 20%;
    }
    position: relative;
    & > div {
      position: sticky;
    }
  }
`;
export const AdvBannerContainer = styled.div`
  margin-top: 65px;
`;
export const PopularFiltersStyled = styled.div<{
  hasBanner: boolean;
}>`
  margin-top: ${(props) => (props.hasBanner ? '0' : '60px')};
`;
