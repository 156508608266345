import React from 'react';

import { IProduct } from '../../types';
import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';
import { ReviewsGraph } from '../ReviewsGraph';
import styled from '@emotion/styled';
import { useParams } from 'react-router-dom';
import { useConfiguration } from '../../hooks/useConfiguration';
import { GET_LOCALIZED } from '../../queries';
import { useQuery } from '@apollo/client';
import { theme } from '../../theme/theme';

export const PdpRating = ({ product }: { product: IProduct }) => {
  const { storeId = '', termId, harvestId = '' } = useParams();
  const { LanguageVersion } = useConfiguration({ storeId, harvestId });
  const { data: translation } = useQuery(GET_LOCALIZED, {
    variables: {
      defaultLanguage: LanguageVersion,
    },
  });

  return (
    <PdpRatingStyled>
      <div>
        <Stack spacing={1} className="stack">
          <div className="rating-and-text">
            <Rating
              name="half-rating-read"
              defaultValue={product.RROverall}
              precision={0.5}
              readOnly
            />
            <p>
              {product.RROverall} {translation?.getLocalization.OutOf}
            </p>
          </div>
          <Reviews>
            {product.RRAmount} {translation?.getLocalization.Reviewers}
          </Reviews>
        </Stack>
      </div>
      <div className="graph">
        <ReviewsGraph totalReviews={product.RROverall} />
      </div>
    </PdpRatingStyled>
  );
};

const PdpRatingStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 33px;

  @media (max-width: 768px) {
    display: block;

    .buttons {
      display: flex;
    }
  }

  .stack {
    margin-bottom: 34px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .rating-and-text {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
  }

  .rating-and-text > p {
    margin-left: 10px;
  }

  .reviews {
    margin-top: 5px;
  }

  .graph {
    display: none;
    min-width: 230px;
  }
`;

const ButtonSeeStyled = styled.button`
  padding: 9px 16px;
  margin-right: 22px;
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: ${({ theme }: any) => theme.productAddToCartBg};
  border: 0.8px solid ${({ theme }: any) => theme.productAddToCartBg};
  background-color: transparent;
`;

const ButtonWriteStyled = styled.button`
  padding: 9px 16px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: ${theme.colors.white};
  border: none;
  background-color: ${({ theme }: any) => theme.productAddToCartBg};
`;

const Reviews = styled.span`
  margin: 10px 0;
`;
