import { IProduct } from '../../types';
import { theme } from '../../theme/index';
import styled from '@emotion/styled';

interface PriceDisplayProps {
  product: IProduct;
}

export const PriceSection = ({ product }: PriceDisplayProps) => {
  const price = (
    product.Price > 0 ? product.Price : product.RegularPrice
  ).toFixed(2);
  const regularPrice = product.RegularPrice.toFixed(2);

  const [priceDollars, priceCents] = price.split('.');
  const [regularPriceDollars, regularPriceCents] = regularPrice.split('.');

  const isDiscounted = parseFloat(price) < parseFloat(regularPrice);

  return (
    <Container>
      <PriceText>
        <DollarSup>$</DollarSup>
        <Dollars>{priceDollars}</Dollars>
        <Cents>{priceCents}</Cents>
      </PriceText>
      {isDiscounted && (
        <TypicalPrice>
          <div style={{ textDecoration: 'line-through' }}>
            Typical Price:
            <DollarSup>$</DollarSup>
            {regularPriceDollars}
            <Cents>{regularPriceCents}</Cents>
          </div>
        </TypicalPrice>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
`;

const PriceText = styled.p`
  font-size: 20px;
  font-weight: bold;
  display: flex;
  flex-direction: row;
`;

const DollarSup = styled.sup`
  font-size: 12px;
  line-height: 1;
`;

const Dollars = styled.span`
  font-size: 20px;
  line-height: 1;
`;

const Cents = styled.sup`
  font-size: 12px;
  line-height: 1;
`;

const TypicalPrice = styled.p`
  color: ${theme.amazon.grey};
  font-size: 14px;
  font-weight: 600;
  margin: 0px 8px;
`;
