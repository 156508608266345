import styled from '@emotion/styled';
import Button from '@mui/material/Button';
import { Typography, keyframes } from '@mui/material';
import { theme } from '../../theme';
const rotateUp = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(180deg);
  }
`;

const rotateDown = keyframes`
  from {
    transform: rotate(180deg);
  }
  to {
    transform: rotate(0deg);
  }
`;
export const PdpCardStyled = styled.div`
  background: ${theme.amazon.white};
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 4px 6px 0;
  grid-column: 3 / span 1;
  width: 100%;
  .sponsored-container {
    width: 100%;
    display: flex;
    padding-bottom: 10px;
    .sponsored {
      margin-left: auto;
      font-size: 12px;
      font-weight: 500;
      color: ${theme.amazon.greyDark};
    }
  }

  .loading-bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }
  .link {
    color: ${theme.amazon.primary};
  }
  .limitedTimeDeal {
    margin-top: 5px;
    display: inline-block;
    padding: 6px;
    font-weight: 600;
    border-radius: 2px;
    background-color: ${theme.amazon.red};
    color: ${theme.amazon.white};
  }

  & > p:nth-of-type(1) {
    max-width: 600px;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    color: ${theme.amazon.greyDark};
    margin-bottom: 16px;
    margin-top: 10px;
  }

  .price {
    font-weight: 700;
    font-size: 20px;
    line-height: 22px;
    color: ${theme.amazon.black};
    & > span {
      color: #777;
      font-weight: 400;
    }
  }

  .addToCartPDPButton {
    margin: 15px 0 15px 0;
    float: right;
  }

  .oldPrice {
    font-size: 15px;
    color: ${theme.amazon.greyDark};
    text-decoration: line-through;
  }

  .attributes {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: ${theme.amazon.black};
    margin: 10px 0;
  }

  .shipping-methods {
    .container {
      display: flex;
      gap: 10px;
    }
    p {
      font-weight: 700;
      font-size: 12px;
      line-height: 15px;
      color: ${theme.amazon.black};
      margin-bottom: 24px;
      text-decoration-line: none;
      margin-bottom: 16px;
    }
    .description {
      margin: 20px 0px;
      font-style: normal;
      font-size: 12px;
      line-height: 15px;
      max-width: 400px;
    }
  }

  .brand-line {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    p:nth-of-type(1) {
      font-weight: 600;
      font-size: 12px;
      line-height: 12px;
      text-decoration-line: underline;
      color: ${theme.amazon.primary};
      display: inline-block;
    }

    & > div {
      display: flex;
      align-items: center;
    }
  }

  & > p:nth-of-type(2) {
    margin-bottom: 10px;
  }

  & > p:nth-of-type(4) {
    margin: 12px 0;
  }
  & > p > span {
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    color: ${theme.amazon.black};
    margin-bottom: 24px;
  }

  .rating {
    display: flex;
    align-items: center;
    & > span {
      color: ${theme.amazon.primary};
      font-size: 10px;
      font-weight: 600;
      margin-left: 5px;
    }
  }
  .cart {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
    & > p {
      justify-content: flex-start;
      color: green;
      font-size: 18px;
      font-weight: 600;
    }
    .cart-manipulation {
      width: 100%;
      & > div {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
        border: 1px solid rgba(0, 0, 0, 0.23);
        border-radius: 4px;
        background: ${theme.amazon.greyLight};
        margin: 8px 0;
        svg {
          width: 16px;
          height: 16px;
        }
      }
      & > span {
        margin: 0 10px;
        @media (max-width: 991px) {
          margin: 0 3px;
        }
      }
    }
    & > button:nth-of-type(1) {
      background: ${theme.amazon.yellow};
    }
    & > button:nth-of-type(2) {
      margin-bottom: 10px;
      background: ${theme.amazon.orange};
    }
    & > button {
      width: 100%;
      border-radius: 20px;
    }
    .delivery-info p {
      margin: 10px 0;
      color: ${theme.amazon.black};
      font-size: 14px;
    }
    .date-bold {
      font-weight: bold;
    }
    .order-deadline {
      font-weight: bold;
      color: ${theme.amazon.primary};
    }
    & > .deliver-address {
      display: flex;
      width: 100%;
      gap: 10px;
      font-size: 14px;
      color: ${theme.amazon.primary};
    }
  }

  .variants-container {
    & > p {
      margin: 10px 0;
    }
    .variants-inner-container {
      & > .selectContainer {
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: ${theme.amazon.black};
        margin: 10px 0;
        display: flex;
        flex-direction: column;

        & > span {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .faIcon {
            margin-left: auto;
          }
          .faIcon.rotate-up {
            animation: ${rotateUp} 0.3s forwards;
          }

          .faIcon.rotate-down {
            animation: ${rotateDown} 0.3s forwards;
          }
        }
        .attribute-value-open,
        .attribute-value-closed {
          font-size: 14px;
          font-weight: 700;
        }
      }
    }
    .variants {
      display: flex;
      gap: 16px;
      max-width: 312px;
      overflow-x: auto;

      @media (max-width: 768px) {
        max-width: 100%;
      }

      .active {
        border: 2px solid ${theme.amazon.orange};
      }

      & > div {
        border: 1px solid ${theme.amazon.grey};
        border-radius: 3px;
        max-width: 105px;
        min-width: 100px;
        min-height: 70px;
        padding: 8px;
        display: flex;
        flex-direction: column;
        align-items: center;
        img {
          height: 85px;
          width: 85px;
          object-fit: contain;
          display: block;
        }
        p {
          margin-top: 8px;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 15px;
          color: ${theme.amazon.black};
          display: -webkit-box;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 5;
        }
        .variant-price {
          font-weight: 700;
        }
      }
    }
  }
`;
export const QuantityTypography = styled(Typography)`
  font-weight: 500;
  font-size: 16px;
`;
export const GalleryContainer = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 50px;
  .share-icon-container,
  .heart-icon-container {
    position: absolute;
    width: 40px;
    height: 40px;
    background-color: ${theme.amazon.white};
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    font-size: 24px;
    z-index: 100;
  }

  .share-icon-container {
    top: 0;
    right: 0;
  }

  .heart-icon-container {
    bottom: 0;
    left: 0;
  }
`;
export const InfoSection = styled.div`
  padding: 8px;
  border-radius: 4px;
  margin: 10px 0;
  font-size: 14px;
`;

export const InfoLine = styled.div`
  display: grid;
  grid-template-columns: 150px 1fr;
  align-items: center;
  gap: 24px;
  margin: 10px 0;
`;

export const Title = styled.span`
  font-weight: bold;
  display: block;
`;

export const Content = styled.span`
  grid-column: 2;
`;

export const AddButton = styled.button`
  background: transparent;
  border: none;
  text-decoration: underline;
`;

export const ButtonStyled = styled(Button)`
  color: ${theme.amazon.black};
  background: ${(props: any) => props.theme.productAddToCartBg};
  padding: 9px 16px;
  border-radius: 3px;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  min-height: 40px;
  text-transform: capitalize;
  &:hover {
    background: ${(props: any) => props.theme.productAddToCartBg};
  }
`;

export const ShippingMethodStyled = styled.button<{
  isActive?: boolean;
}>`
  &:hover {
    cursor: pointer;
  }
  height: 40px;
  text-align: left;
  text-decoration: none;
  padding: 10px;
  flex: 1;
  background-color: ${({ isActive }) => (isActive ? '#f4fdfe' : '#ffffff')};
  box-shadow: ${({ isActive, theme }: any) =>
    isActive
      ? `0 0 0 0.125rem ${theme.productAddToCartBg}`
      : '0 0 0 0.0625rem #bbc8d1'};
  border-radius: 5px;
  border: none;

  white-space: nowrap;

  svg {
    color: ${({ theme }: any) => theme.productAddToCartBg};
  }
`;
