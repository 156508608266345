import styled from "@emotion/styled";
import { Box, ListItemButton, ListItemText, Typography } from "@mui/material";
import { theme } from "../../theme";

export const StyledModalBox = styled(Box)`
  width: 200px;
  max-height: 500px;
  border-radius: 10px;
  background: ${theme.amazon.white};
  box-shadow: 24px;
  overflow-y: auto; 
`;

export const StyledListItemText = styled(ListItemText)`
    && {
    font-size: 16px;
  }
`;

export const SelectHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 10px;
  background-color: rgba(0, 0, 0, 0.07);
  height: 50px;
`;

export const HeaderTypography = styled(Typography)`
  font-weight: 600;
  font-size: 12px;
`;

export const StyledListItemButton = styled(ListItemButton)<{ selected?: boolean }>`
  ${({ selected }) => selected ? `
    border-top: 1px solid ${theme.amazon.primary};
    border-right: 1px solid ${theme.amazon.primary};
    border-bottom: 1px solid ${theme.amazon.primary};
    border-left: 4px solid ${theme.amazon.primary};
  ` : ''}
`;