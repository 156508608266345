import React, { useEffect, useState } from 'react';
import { useAppContext } from '../../../context';
import { useParams } from 'react-router-dom';
import { useConfiguration } from '../../../hooks/useConfiguration';
import { GET_LOCALIZED } from '../../../queries';
import { useQuery } from '@apollo/client';
import { FilterModalCommonStyles } from '../commonStyles';
import styled from '@emotion/styled';
import { Chip } from '../../Chip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { actions } from '../../../context/actions';
import { theme } from '../../../theme/theme';

export const VariantsFilter = ({ buttonsOff }: any) => {
  const {
    value: { searchFilters },
    dispatch,
  } = useAppContext();

  const variantsFilter = searchFilters.find(
    (filter: any) => filter.name === 'Variants'
  );
  const [value, setValue] = useState(variantsFilter?.value || '');

  const { storeId = '', termId, harvestId = '' } = useParams();
  const { LanguageVersion } = useConfiguration({ storeId, harvestId });
  const { data: translation } = useQuery(GET_LOCALIZED, {
    variables: { defaultLanguage: LanguageVersion },
  });

  useEffect(() => {
    const currentFilter = searchFilters.find(
      (filter: any) => filter.name === 'Variants'
    );
    setValue(currentFilter?.value || '');
  }, [searchFilters]);

  const handleSelect = (selectedValue: string) => {
    const newValue = value === selectedValue ? null : selectedValue;
    setValue(newValue);
    dispatch({
      type:
        newValue === null
          ? actions.REMOVE_SEARCH_FILTER
          : actions.ADD_SEARCH_FILTER,
      payload: { name: 'Variants', value: newValue },
    });
  };

  const variantOptions = [
    { key: 'yes', label: translation?.getLocalization.ShowProductWithVariants },
    { key: 'no', label: translation?.getLocalization.ShowProductWithNoVariants },
  ];

  return (
    <FilterModalCommonStyles buttonsOff={buttonsOff}>
      <ComponentStyled>
        <div className="variant-chips">
          {variantOptions.map((option) => (
            <Chip
              key={option.key}
              title={option.label}
              onClick={() => handleSelect(option.key)}
              addinitonalIcon={
                value === option.key ? (
                  <FontAwesomeIcon icon={faTimes} />
                ) : undefined
              }
              fontColor={value === option.key ? `${theme.colors.primary}` : ''}
              background={
                value === option.key
                  ? `${theme.colors.buttonActive}`
                  : `${theme.colors.white}`
              }
            />
          ))}
        </div>
      </ComponentStyled>
    </FilterModalCommonStyles>
  );
};

const ComponentStyled = styled.div``;
