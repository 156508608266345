import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronUp,
  faChevronDown,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import Button from '@mui/material/Button';
import { motion } from 'framer-motion';
import { VariantsFilter } from '../Filters/VariantsFilter';
import { BrandsFilterModal } from '../Filters/BrandsFilter';
import { FiltersMenuStyled } from './styles';
import { useFiltersMenu } from './useFiltersMenu';
import { IProduct } from '../../types';
import { Sorting } from '../Filters/Sorting';
import { PriceRangeFilter } from '../Filters/PriceRangeFilter';
import { RatingFilter } from '../Filters/RatingFilter';
import { useQuery } from '@apollo/client';
import { GET_LOCALIZED } from '../../queries';
import { useNavigate, useParams } from 'react-router-dom';
import { useConfiguration } from '../../hooks/useConfiguration';
import { useAppContext } from '../../context';
import { Slide } from '@mui/material';

export const FiltersMenu = ({
  productsBeforeFiltering,
  isSlide,
}: {
  productsBeforeFiltering: IProduct[];
  isSlide?: boolean;
}) => {
  const {
    modalType,
    handleFiltersVisibility,
    isOpen,
    handleOpen,
    handleUpdate,
    handleReset,
    isInlineFilters,
  } = useFiltersMenu();

  const { storeId = '', harvestId = '' } = useParams();

  const { LanguageVersion } = useConfiguration({ storeId, harvestId });
  const { value } = useAppContext();
  const productCount = value.productLength;
  const { data: translation } = useQuery(GET_LOCALIZED, {
    variables: {
      defaultLanguage: LanguageVersion,
    },
  });
  const filtersList = value.searchFilters.length > 0;
  const filters = [
    {
      name: translation?.getLocalization.Price,
      component: (
        <PriceRangeFilter
          buttonsOff={true}
          productsBeforeFiltering={productsBeforeFiltering}
        />
      ),
    },
    {
      name: translation?.getLocalization.Rating,
      component: <RatingFilter buttonsOff={true} />,
    },
    {
      name: translation?.getLocalization.Variants,
      component: <VariantsFilter buttonsOff={true} />,
    },
    {
      name: translation?.getLocalization.Brand,
      component: (
        <BrandsFilterModal
          productsBeforeFiltering={productsBeforeFiltering}
          buttonsOff={true}
        />
      ),
    },
    {
      name: translation?.getLocalization.SortBy,
      component: <Sorting buttonsOff={true} />,
    },
  ];

  const container = {
    hidden: { height: 0, opacity: 0 },
    visible: {
      height: 'auto',
      opacity: 1,
    },
  };
  return (
    <Slide direction="down" in={isSlide} mountOnEnter unmountOnExit>
      <FiltersMenuStyled
        modalType={modalType}
        isInlineFilters={isInlineFilters}
      >
        <div className="filter-header">
          <span>{translation?.getLocalization.AllFilters}</span>
          <FontAwesomeIcon icon={faTimes} onClick={handleFiltersVisibility} />
        </div>
        <div className="filters">
          {filters.map((filter: any, index: number) => (
            <div key={index} className="filter-container">
              <div className="filter">
                <span onClick={() => handleOpen(filter?.name)}>
                  {filter.name}
                </span>
                <FontAwesomeIcon
                  className="faIcon"
                  onClick={() => handleOpen(filter?.name)}
                  icon={isOpen[filter.name] ? faChevronUp : faChevronDown}
                />
              </div>
              {isOpen[filter.name] && (
                <motion.div
                  initial="hidden"
                  animate="visible"
                  variants={container}
                >
                  {filter.component}
                </motion.div>
              )}
            </div>
          ))}
        </div>
        <div className="filter-footer">
          <div className="button-footer">
            {filtersList && (
              <Button
                fullWidth
                variant="contained"
                className="reset-button"
                onClick={handleReset}
              >
                reset
              </Button>
            )}
            <Button
              fullWidth
              variant="contained"
              className="result-button"
              onClick={handleUpdate}
            >
              {translation?.getLocalizationAmzWm?.Show50Result || `Show ${productCount} result`}
            </Button>
          </div>
        </div>
      </FiltersMenuStyled>
    </Slide>
  );
};
