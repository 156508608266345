import { IProduct } from '../types';
import { actions } from './actions';
import { dynataAddToCart, normalizeNameForUrl } from '../helpers'

export const reducer = (state: any, action: any) => {
  switch (action.type) {
    case actions.TOGGLE_MODAL:
      state.isModal = action.payload.isModal;
      state.modalType = action.payload && action.payload.modalType;
      state.modalContent = action.payload && action.payload.modalContent;
      break;

    case actions.TOGGLE_INLINE_FILTERS:
      state.isInlineFilters = action.payload.isInlineFilters;
      break;

    case actions.ADD_SEARCH_FILTER:
      const existingFilter = state.searchFilters.find(
        (filter: any) => filter.name === action.payload.name
      );
      if (!existingFilter) {
        state.searchFilters.push(action.payload);
      }

      if (existingFilter) {
        state.searchFilters = state.searchFilters.filter(
          (filter: any) => filter.name !== action.payload.name
        );
        state.searchFilters.push(action.payload);
      }

      break;
      case actions.SET_PRODUCT_LENGTH:
        return { ...state, productLength: action.payload };

    case actions.REMOVE_SEARCH_FILTER:
      if (!action.payload) {
        state.searchFilters = [];
      }

      if (action.payload) {
        const clearFilters = state.searchFilters.filter(
          (filter: any) => filter.name !== action.payload.name
        );
        clearFilters.push(action.payload);
        state.searchFilters = clearFilters;
      }

      break;

      case actions.SET_TERM_ID:
        return { ...state, termId: action.payload };

    case actions.ADD_PRODUCTS_SORTING:
      const existingSorting = state.productsSorting.find(
        (filter: any) => filter.name === action.payload.name
      );
      if (!existingSorting) {
        state.productsSorting.push(action.payload);
      }

      if (existingSorting) {
        state.productsSorting = state.productsSorting.filter(
          (filter: any) => filter.name !== action.payload.name
        );
        state.productsSorting.push(action.payload);
      }
      break;
    case actions.REMOVE_PRODUCTS_SORTING:
      state.productsSorting = [];
      break;

    case actions.ADD_TO_CART:
      const itemToAddExists = state.cart.find(
        (item: IProduct) => item.id === action.payload.id
      );

      const newAmount = action?.payload?.newAmount;
      const shippingMethod = action?.payload?.shippingMethod;

      if (itemToAddExists) {
        if (itemToAddExists.amount !== 12) {
          itemToAddExists.amount = newAmount
            ? newAmount
            : itemToAddExists.amount + 1;

          itemToAddExists.shippingMethod = shippingMethod;
          if (action.payload.source === 'cart') {
            let price = action.payload.regularPrice ? action.payload.regularPrice : action.payload.price;
            dynataAddToCart(action.payload.href + 'ips/' + action.payload.id + '__' + normalizeNameForUrl(action.payload.name) + '_' + price + '_cartadd' + itemToAddExists.amount);
          }
        }
      } else {
        state.cart = [
          ...state.cart,
          {
            ...action.payload,
            amount: newAmount ? newAmount : 1,
            shippingMethod,
          },
        ];
      }
      break;
      

    case actions.REMOVE_FROM_CART:
      const itemToRemove = state.cart.find(
        (item: IProduct) => item.id === action.payload.product.id
      );

      if (action.payload.type === 'REMOVE_ONE') {
        if (itemToRemove && itemToRemove?.amount === 1)
          state.cart = state.cart.filter(
            (item: IProduct) => item.id !== action.payload.product.id
          );

        if (itemToRemove && itemToRemove?.amount !== 0)
          itemToRemove.amount = itemToRemove.amount - 1;
          if (action.payload.product.source === 'cart') {
            let price = action.payload.product.regularPrice ? action.payload.product.regularPrice : action.payload.product.price;
            dynataAddToCart(action.payload.product.href + 'ips/' + action.payload.product.id + '__' + normalizeNameForUrl(action.payload.product.name) + '_' + price + '_cartadd' + itemToRemove.amount);
          }
      }

      if (action.payload.type === 'REMOVE_ALL') {
        state.cart = state.cart.filter(
          (item: IProduct) => item.id !== action.payload.product.id
        );
        if (action.payload.product.source === 'cart') {
          let price = action.payload.product.regularPrice ? action.payload.product.regularPrice : action.payload.product.price;
          dynataAddToCart(action.payload.product.href + 'ips/' + action.payload.product.id + '__' + normalizeNameForUrl(action.payload.product.name) + '_' + price + '_cartadd0');
        }
      }

      break;

    case actions.CHECKOUT:

      const itemsInCart = state.cart.find(
        (item: IProduct) => item.id !== action.payload.product.id
      );

      state.cart = state.cart.filter(
        (item: IProduct) => item.id !== action.payload.product.id
      );

    break;
    

    default:
      return state;
  }
};
