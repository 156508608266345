import React from 'react';
import styled from '@emotion/styled';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper';
import { IProduct } from '../../types';
import { imgSrcByEnv } from '../../../config/imgSrcByEnv';
import { contentSquarePageEvent } from '../../helpers';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { theme } from '../../theme/theme';

export const PdpGallery = ({ product }: { product: IProduct }) => {
  const gallery = product?.Gallery;
  const [currentImage, setCurrentImage] = React.useState(
    gallery && gallery[0]?.image?.fullpath
  );
  const video = product?.VideoGallery;
  let arr = [""];

  gallery && gallery.map(({ image: { fullpath } }) => {
    arr.push(fullpath);
  });

  video && video.map(({ Video: { data, poster }, VideoPosition }) => {
    arr.splice( Number(VideoPosition), 0, data.fullpath + '^^^' + poster.fullpath);
  });
  arr.shift();

  React.useEffect(() => {
    setCurrentImage(gallery && gallery[0]?.image?.fullpath);
  }, [gallery]);

  return (
    <GalleryStyled>
      <div className="big-image-container">
        <Swiper
          modules={[Navigation, Pagination]}
          spaceBetween={50}
          slidesPerView={1}
          pagination={{ clickable: true, el: '.big-image-pagination' }}
          navigation={true}
          onSlideChange={(swiper) =>
            setCurrentImage(gallery[swiper.activeIndex]?.image?.fullpath)
          }
        >
          {arr && arr.map((elem) => {
            let videoPaths = elem.split('^^^');
            return (
              <SwiperSlide key={elem}>
                {!elem.includes('Videos') ? (
                  <img className="big-image" src={`${imgSrcByEnv()}${elem}`} alt={'product image'} />
                ) : (
                  <video
                    className="big-image"
                    src={`${imgSrcByEnv()}${videoPaths[0]}`}
                    controls
                    autoPlay
                    muted
                    loop
                  />
                )}
              </SwiperSlide>
            );
          })}
        </Swiper>
        <div className="big-image-pagination"></div>
      </div>
    </GalleryStyled>
  );
};

const GalleryStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;

  .big-image-container {
    position: relative;
    width: 100%;
    max-height: 600px;

    .big-image {
      width: 100%;
      height: 400px;
      object-fit: contain;
    }
    .swiper-button-next, .swiper-button-prev{
      color: ${theme.colors.blueLight};
    }

    .big-image-pagination {
      position: absolute;
      bottom: -20px;
      width: 100%;
      display: flex;
      justify-content: center;
      margin-bottom: 10px;

      .swiper-pagination-bullet {
        background: ${theme.colors.grey};
        opacity: 0.6;
      }

      .swiper-pagination-bullet-active {
        background: ${theme.colors.blueLight};
      }
    }
  }

  .thumbnails-container {
    max-height: 552px;
    padding-bottom: 40px;

    .swiper-slide {
      width: auto;
    }
  }

  .image {
    display: block;
    width: 58px;
    height: 58px;
    object-fit: contain;
    cursor: pointer;
  }
`;
