import React from 'react';

import { useParams } from 'react-router-dom';
import { useAppContext } from '../../context';
import { actions } from '../../context/actions';
import { useConfiguration } from '../../hooks/useConfiguration';

export const useFiltersMenu = () => {
  const { storeId = '', harvestId = '' } = useParams();

  const {
    value: { modalType, isInlineFilters, searchFilters, productsSorting },
    dispatch,
  } = useAppContext();

  const { FilterVisibility } = useConfiguration({ storeId, harvestId });

  const handleFiltersVisibility = () => {
    if (window.innerWidth < 768) {
      dispatch({
        type: actions.TOGGLE_MODAL,
        payload: { isModal: false },
      });
    } else {
      if (FilterVisibility === 'Stick') {
        dispatch({
          type: actions.TOGGLE_INLINE_FILTERS,
          payload: {
            isInlineFilters: false,
          },
        });
      } else {
        dispatch({
          type: actions.TOGGLE_MODAL,
          payload: { isModal: false },
        });
      }
    }
  };

  const [isOpen, setIsOpen] = React.useState<{ [key: string]: boolean }>({});

  const handleOpen = (name: string) => {
    setIsOpen({ ...isOpen, [name]: !isOpen[name] });
  };

  const handleUpdate = () => {
    const buttons = document.querySelectorAll('.submit-filter');
    buttons.forEach((button: any) => button.click());
    dispatch({
      type: actions.TOGGLE_MODAL,
      payload: { isModal: false },
    });
  };

  const handleReset = () => {
    // clear-filter
    // const buttons = document.querySelectorAll('.clear-filter');
    // buttons.forEach((button: any) => button.click());
    dispatch({
      type: actions.REMOVE_SEARCH_FILTER,
    })
  };

  React.useEffect(() => {
    const brandsFilter = searchFilters.find(
      (filter: any) => filter.name === 'Brand'
    );
    const brands =
      brandsFilter && brandsFilter.brands && Object.values(brandsFilter.brands);
    const isBrands = brands && brands.some((brand: any) => brand === true);
    setIsOpen((i) => ({ ...i, Brands: isBrands }));

    const priceFilter = searchFilters.find(
      (filter: any) => filter.name === 'Price'
    );
    if (
      priceFilter?.userSelectedPriceFilterMin ||
      priceFilter?.userSelectedPriceFilterMax
    ) {
      setIsOpen((i) => ({ ...i, Price: true }));
    }

    const ratingFilter = searchFilters.find(
      (filter: any) => filter.name === 'Rating'
    );

    if (ratingFilter?.value) {
      setIsOpen((i) => {
        return { ...i, Rating: true };
      });
    }
    const variantsFilter = searchFilters.find(
      (filter: any) => filter.name === 'Variants'
    );

    if (variantsFilter?.value) {
      setIsOpen((i) => ({ ...i, Variants: true }));
    }

    if (productsSorting?.length) {
      setIsOpen((i) => ({ ...i, 'Sort by': true }));
    }
  }, [searchFilters, productsSorting]);

  return {
    modalType,
    handleFiltersVisibility,
    isOpen,
    handleOpen,
    handleUpdate,
    handleReset,
    isInlineFilters,
  };
};
