import styled from "@emotion/styled";

export const BrandsStyled = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;

  & > div:first-of-type {
    flex: 1;
    margin: 10px;
  }

  h2 {
    font-size: 1.7rem;
    font-weight: 600;
  }

  .search-bar {
    display: flex;
    align-items: center;
    border-radius: 20px;
    border: 1px solid #e2dede;
    padding: 5px;
    margin-top: 5%;
    svg {
      color: #8e8e93;
      font-size: 1.8rem;
    }
    input {
      flex-grow: 1;
      border: none;
      color: #8e8e93;
      outline: none;
      height: 23px;
    }
  }

  .MuiFormControlLabel-label {
    font-size: 1.3rem;
  }
  .MuiSvgIcon-root {
    font-size: 2rem;
  }
  .checkboxes {
    margin-top: 5%;
    .MuiCheckbox-root {
      color: #000;
    }
  }
`;
