import React from 'react';

import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styled from '@emotion/styled';
import { theme } from '../../theme/theme';

type Tcolors = string | (() => string);

interface IProps {
  title: string;
  onClick: () => void;
  isExpand?: boolean;
  addinitonalIcon?: React.ReactNode;
  additionalIconColor?: string;
  colors?: Tcolors;
  fontColor?: Tcolors;
  background: Tcolors;
  rating?: React.ReactNode;
}

export const Chip = ({
  title,
  onClick,
  isExpand,
  addinitonalIcon,
  additionalIconColor,
  colors,
  rating,
  fontColor,
  background,
}: IProps) => {
  return (
    <ChipStyled
      onClick={onClick}
      colors={colors}
      fontColor={fontColor}
      background={background}
      additionalIconColor={additionalIconColor}
    >
      {addinitonalIcon && (
        <span className="additional-icon">{addinitonalIcon}</span>
      )}
      {rating && <span>{rating}</span>}
      {title}
      {isExpand && <FontAwesomeIcon className="expand" icon={faChevronDown} />}
    </ChipStyled>
  );
};

const ChipStyled = styled.span<{
  colors?: Tcolors;
  background?: Tcolors;
  fontColor?: Tcolors;
  additionalIconColor?: string;
}>`
  background: ${({ background, colors }) =>
    colors === 'dark' ? '#DBDBDB' : background};
  color: ${({ fontColor }) => fontColor || '#000'};
  border: 1px solid #dbdbdb;
  border-radius: 5px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 5px 14px;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  margin: 5px 4px;

  cursor: pointer;

  .additional-icon {
    display: flex;
    align-items: center;
    margin-right: 6px;
    height: 100%;

    div,
    svg {
      color: ${({ additionalIconColor }) =>
        additionalIconColor || `${theme.colors.primary}`};
    }
  }
  .expand {
    margin-left: 7px;
  }
`;
