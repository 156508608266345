import React from 'react';
import styled from '@emotion/styled';
import { IProduct } from '../../types';
import { PopularProductItem } from '../PopularProductItem';
import { theme } from '../../theme';

export const PopularProducts = ({
  products,
  title,
}: {
  products: IProduct[];
  title: string | undefined;
}) => {
  return (
    <PopularProductsStyled>
      <HeaderStyled>{title}</HeaderStyled>
      <PopularProductItem products={products} />
    </PopularProductsStyled>
  );
};

const PopularProductsStyled = styled.div`
  padding-left: 16px;
  padding-right: 8px;
  background: ${theme.amazon.white};
`;
const HeaderStyled = styled.h1`
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 16px;
  color: ${theme.amazon.black};
  padding: 16px 8px;
`;
