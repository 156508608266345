import React from 'react';

import { useQuery } from '@apollo/client';
import { GET_PRODUCT_CATEGORY_BY_TERM } from '../../../queries';
import { useNavigate, useParams } from 'react-router-dom';

import { IProduct } from '../../../types';
import { FilterModalCommonStyles } from '../commonStyles';
import { BrandsStyled } from './styles';
import { useBrandsFilter } from './useBrandsFilter';

import { useConfiguration } from '../../../hooks/useConfiguration';
import { GET_LOCALIZED } from '../../../queries';
import { Chip } from '../../Chip';
import { theme } from '../../../theme/theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

export const BrandsFilterModal = ({
  productsBeforeFiltering,
  buttonsOff,
}: any) => {
  const { searchBar, handleClear, handleCheckboxes, checked } = useBrandsFilter(
    { productsBeforeFiltering }
  );

  const { storeId = '', termId, harvestId = '' } = useParams();

  const { LanguageVersion } = useConfiguration({ storeId, harvestId });

  const { data: translation } = useQuery(GET_LOCALIZED, {
    variables: {
      defaultLanguage: LanguageVersion,
    },
  });

  const { data, loading } = useQuery(
    GET_PRODUCT_CATEGORY_BY_TERM({
      termId: termId?.split('__')[0],
    })
  );

  const edges = data?.getEPogTermListing?.edges;

  const brands = edges
    .map((edge: any) => edge?.node?.BrandFilterElements)
    .flat();

  if (brands.length > 1) {
    return (
      <FilterModalCommonStyles buttonsOff={buttonsOff}>
        <BrandsStyled>
          <div className="checkboxes">
            {brands &&
              Object.values(brands).map((value, index: number) => {
                return (
                  <Chip
                    key={index}
                    title={brands[index].BrandName}
                    onClick={() => handleCheckboxes(brands[index].BrandName)}
                    isExpand={false}
                    addinitonalIcon={
                      checked[brands[index].BrandName] && (
                        <FontAwesomeIcon icon={faTimes} />
                      )
                    }
                    fontColor={
                      checked[brands[index].BrandName]
                        ? `${theme.colors.primary}`
                        : ''
                    }
                    background={
                      checked[brands[index].BrandName]
                        ? `${theme.colors.buttonActive}`
                        : `${theme.colors.white}`
                    }
                  />
                );
              })}
          </div>
        </BrandsStyled>
      </FilterModalCommonStyles>
    );
  } else {
    return (
      <FilterModalCommonStyles buttonsOff={buttonsOff}>
        <BrandsStyled>
          <div className="checkboxes">
            {productsBeforeFiltering ? (
              productsBeforeFiltering
                .filter(
                  (product: IProduct, index: number, arr: IProduct[]) =>
                    product.Brand?.toLowerCase().includes(
                      searchBar.toLowerCase()
                    ) &&
                    index ===
                      arr.findIndex((item) => item.Brand === product.Brand)
                )
                .map((product: IProduct, index: number) => (
                  <Chip
                    key={index}
                    title={product.Brand}
                    onClick={() => handleCheckboxes(product.Brand)}
                    isExpand={false}
                    addinitonalIcon={
                      checked[product.Brand] && (
                        <FontAwesomeIcon icon={faTimes} />
                      )
                    }
                    fontColor={
                      checked[product.Brand] ? `${theme.colors.blueLight}` : ''
                    }
                    background={
                      checked[product.Brand]
                        ? `${theme.colors.buttonActive}`
                        : `${theme.colors.white}`
                    }
                  />
                ))
            ) : (
              <div></div>
            )}
          </div>
        </BrandsStyled>
      </FilterModalCommonStyles>
    );
  }
};
