import styled from '@emotion/styled';
import { theme } from '../../theme';

export const PdpRouteStyled = styled.div`
  margin-top: 60px;
  .details {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: flex-start;
    width: 100%;
    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
    }
  }

  & > h2:nth-of-type(1),
  div > h2,
  h2:nth-of-type(2),
  h3:nth-of-type(1),
  h3:nth-of-type(2),
  h3:nth-of-type(3),
  & > div > p {
    margin: 0 10px;
    font-weight: 700;
    color: ${theme.amazon.black};
  }

  & > h2:nth-of-type(1),
  div > h2 {
    margin-top: 96px;
    font-size: 18px;
    line-height: 29px;
    margin-bottom: 24px;
    @media (max-width: 768px) {
      margin: 30px 10px;
      font-size: 16px;
    }
  }

  h2:nth-of-type(2),
  h3:nth-of-type(1) {
    font-size: 24px;
    line-height: 29px;
    @media (max-width: 768px) {
      font-size: 20px;
    }
  }

  hr {
    margin: 16px 0;
    & + hr {
      margin: 48px 0 64px;
    }
    @media (max-width: 768px) {
      margin: 24px 0;
    }
  }
  .description,
  .reviews,
  .specifications,
  .additional-tab {
    max-width: 450px;
    & > h3:nth-of-type(1),
    & > h3:nth-of-type(2),
    & > div,
    & > p {
      margin: 0 10px;
      font-size: 14px;
      line-height: 17px;
      color: ${theme.amazon.black};
    }
    & > h3:nth-of-type(1) {
      font-weight: 600;
      font-size: 18px;
      margin-bottom: 10px;
    }
    & > div {
      p {
        & > img {
          width: 100%;
          height: auto;
        }
      }
      & table {
        display: block;
        width: 100%;
        overflow-x: auto;
        border-collapse: collapse;
        & th,
        & td {
          border: 1px solid ${theme.amazon.grey};
          padding: 8px;
          text-align: left;
          & h3 {
            font-size: 16px;
            & a {
              color: ${theme.amazon.primary};
              text-decoration: none;
            }
          }
        }
        & img {
          max-width: 400px;
          height: auto;
          display: block;
        }
      }
    }
  }
  .reviews,
  .specifications {
    margin-bottom: 32px;
  }
`;
