import { theme } from '../../theme/index';
import styled from '@emotion/styled';

export const LimitedOfferSection = ({ product }: any) => (
  <Container>
    {product.LabelText && <DiscountButton>{product.LabelText}</DiscountButton>}
  </Container>
);

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  justify-content: space-between;
`;

const DiscountButton = styled.button`
  background-color: ${theme.amazon.red};
  color: ${theme.amazon.white};
  font-family: Montserrat;
  font-weight: 600;
  padding: 6px 6px;
  border-radius: 4px;
  border-width: 0;
  font-size: 14px;
`;
