import styled from '@emotion/styled';
import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { theme } from '../../theme/theme';
interface CenterModalInformationProps {
  description: string;
  isActive?: boolean;
}
export const CenterModalInformation: React.FC<CenterModalInformationProps> = ({
  description,
  isActive = false,
}) => {
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (isActive) {
      timer = setTimeout(() => {
        setShowContent(true);
      }, 800);
    } else {
      setShowContent(true);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [isActive]);

  return showContent ? (
    <StyledModalBox>
      <Typography variant="h5" component="h2">
        {description}
      </Typography>
    </StyledModalBox>
  ) : null;
};

const StyledModalBox = styled(Box)`
  color: ${theme.colors.white};
  background: ${theme.colors.greyDark};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  text-align: right;
  font-size: 12px;
`;
