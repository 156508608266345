import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { FilterModalCommonStyles } from '../commonStyles';
import { useParams } from 'react-router-dom';
import { useAppContext } from '../../../context';
import { actions } from '../../../context/actions';
import { useQuery } from '@apollo/client';
import { GET_LOCALIZED } from '../../../queries';
import { useConfiguration } from '../../../hooks/useConfiguration';
import { Chip } from '../../Chip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { theme } from '../../../theme/theme';
import { IProduct } from '../../../types';

export const PriceRangeFilter = ({
  buttonsOff,
  productsBeforeFiltering,
}: any) => {
  const {
    value: { searchFilters },
    dispatch,
  } = useAppContext();
  const { storeId = '', harvestId = '' } = useParams();
  const { Currency, LanguageVersion } = useConfiguration({
    storeId,
    harvestId,
  });

  const { data: translation } = useQuery(GET_LOCALIZED, {
    variables: {
      defaultLanguage: LanguageVersion,
    },
  });
  const priceFilter = searchFilters.find(
    (filter: any) => filter.name === 'Price'
  );
  const [priceRanges, setPriceRanges] = useState([
    { label: 'All Prices', min: '', max: '' },
    { label: 'Up to $50', min: '', max: '50' },
    { label: '$50 to $100', min: '50', max: '100' },
    { label: '$100 to $150', min: '100', max: '150' },
    { label: '$250 to $400', min: '250', max: '400' },
    { label: '$400 to $600', min: '400', max: '600' },
    { label: '$600 & above', min: '600', max: '' },
  ]);

  const products = [...productsBeforeFiltering];
  const sorted = products.sort(
    (a: IProduct, b: IProduct) => b.RegularPrice - a.RegularPrice
  );

  const productDatabaseMaxPrice = Math.ceil(sorted[0].RegularPrice);
  const productDatabaseMinPrice = Math.floor(
    sorted[sorted.length - 1].RegularPrice
  );
  const initialMinPrice = priceFilter
    ? priceFilter.userSelectedPriceFilterMin.toString()
    : '';
  const initialMaxPrice = priceFilter
    ? priceFilter.userSelectedPriceFilterMax.toString()
    : '';
  const initialSelectedRange = priceFilter ? priceFilter.range : 'All Prices';

  const [minPrice, setMinPrice] = useState(initialMinPrice);
  const [maxPrice, setMaxPrice] = useState(initialMaxPrice);
  const [selectedRange, setSelectedRange] = useState(initialSelectedRange);

  const handleMinPriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMinPrice(event.target.value);
  };

  const handleMaxPriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMaxPrice(event.target.value);
  };

  const updatePriceRange = (newMin: any, newMax: any) => {
    if (!newMin && !newMax) {
      setSelectedRange('All Prices');
      return;
    }

    let newLabel =
      newMin && newMax
        ? `$${newMin} to $${newMax}`
        : newMin
        ? `$${newMin} & above`
        : newMax
        ? `Up to $${newMax}`
        : 'All Prices';

    setPriceRanges((prevRanges) => {
      const allPricesEntry = prevRanges.find(
        (range) => range.label === 'All Prices'
      )!;
      const otherRanges = prevRanges.filter(
        (range) => range.label !== 'All Prices'
      );
      const existingIndex = otherRanges.findIndex((range) => {
        return (
          (newMin && range.min === newMin) || (newMax && range.max === newMax)
        );
      });

      if (existingIndex !== -1) {
        otherRanges[existingIndex] = {
          ...otherRanges[existingIndex],
          min: newMin || otherRanges[existingIndex].min,
          max: newMax || otherRanges[existingIndex].max,
          label: newLabel,
        };
      } else {
        otherRanges.push({
          label: newLabel,
          min: newMin || '',
          max: newMax || '',
        });
      }

      return [allPricesEntry, ...otherRanges];
    });

    setSelectedRange(newLabel);
  };

  const handleBlur = () => updatePriceRange(minPrice, maxPrice);

  useEffect(() => {
    if (searchFilters.length === 0 || !priceFilter) {
      setSelectedRange('All Prices');
      setMinPrice('');
      setMaxPrice('');
    }
  }, [searchFilters]);

  const handleClearPriceFilter = () => {
    setMinPrice('');
    setMaxPrice('');
    setSelectedRange('All Prices');
    dispatch({
      type: actions.REMOVE_SEARCH_FILTER,
    });
  };

  const handlePriceRangeChange = (label: string, min: string, max: string) => {
    if (selectedRange === label || label === 'All Prices') {
      handleClearPriceFilter();
    } else {
      const newMin = min === '' ? '' : parseInt(min).toString();
      const newMax = max === '' ? '' : parseInt(max).toString();
      setMinPrice(newMin);
      setMaxPrice(newMax);
      setSelectedRange(label);
    }
  };

  useEffect(() => {
    if (selectedRange !== 'All Prices') {
      dispatch({
        type: actions.ADD_SEARCH_FILTER,
        payload: {
          name: 'Price',
          userSelectedPriceFilterMin:
            parseInt(minPrice) || productDatabaseMinPrice,
          userSelectedPriceFilterMax:
            parseInt(maxPrice) || productDatabaseMaxPrice,
          range: selectedRange,
          isFiltered: true,
        },
      });
    }
  }, [minPrice, maxPrice, selectedRange]);

  const currency = Currency || '$';

  const getTitle = (label: string) => {
    if (label === 'All Prices') return translation?.getLocalizationAmzWm?.AllPrices || label;
    if (label === 'Up to $50') return (translation?.getLocalizationAmzWm?.UpTo || label) + (translation?.getLocalizationAmzWm?.UpTo ? ' $50' : '');
    if (label === '$50 to $100') return translation?.getLocalizationAmzWm?.Between50And100 || label;
    if (label === '$100 to $150') return translation?.getLocalizationAmzWm?.Between100And150 || label;
    if (label === '$250 to $400') return translation?.getLocalizationAmzWm?.Between250And400 || label;
    if (label === '$400 to $600') return translation?.getLocalizationAmzWm?.Between400And600 || label;
    if (label === '$600 & above') return translation?.getLocalizationAmzWm?.moreThan600 || label;
    return label;
  }
  return (
    <FilterModalCommonStyles buttonsOff={buttonsOff}>
      <PriceRangeStyled>
        <div>
          <div className="chip-options">
            {priceRanges.map((range) => (
              <Chip
                key={range.label}
                title={getTitle(range.label)}
                onClick={() =>
                  handlePriceRangeChange(range.label, range.min, range.max)
                }
                addinitonalIcon={
                  selectedRange === range.label &&
                  range.label !== 'All Prices' ? (
                    <FontAwesomeIcon icon={faTimes} />
                  ) : null
                }
                fontColor={
                  selectedRange === range.label ? `${theme.colors.primary}` : ''
                }
                background={
                  selectedRange === range.label
                    ? `${theme.colors.buttonActive}`
                    : '#fff'
                }
              />
            ))}
          </div>
          <div className="price-inputs">
            <label>
              {translation?.getLocalizationAmzWm?.MinPrice || 'Min Price'}:
              <div className="input-with-symbol">
                <span className="symbol">{currency}</span>
                <input
                  type="number"
                  value={minPrice}
                  onChange={handleMinPriceChange}
                  min={productDatabaseMinPrice}
                  onBlur={handleBlur}
                />
              </div>
            </label>
            <label>
              {translation?.getLocalizationAmzWm?.MaxPrice || 'Max Price'}:
              <div className="input-with-symbol">
                <span className="symbol">{currency}</span>
                <input
                  type="number"
                  value={maxPrice}
                  onChange={handleMaxPriceChange}
                  max={productDatabaseMaxPrice}
                  onBlur={handleBlur}
                />
              </div>
            </label>
          </div>
        </div>
      </PriceRangeStyled>
    </FilterModalCommonStyles>
  );
};
const PriceRangeStyled = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;

  & > div:first-of-type {
    flex: 1;
  }
  h2 {
    font-size: 1.7rem;
    font-weight: 600;
  }

  .price-inputs {
    display: flex;
    justify-content: space-around;
    margin: 20px 0;

    label {
      display: flex;
      flex-direction: column;
      font-size: 14px;
      color: #333;
      font-weight: 500;
      margin-bottom: 10px;

      .input-with-symbol {
        position: relative;
        display: flex;
        align-items: center;

        .symbol {
          position: absolute;
          left: 8px;
          color: ${theme.colors.black};
          font-size: inherit;
        }

        input {
          width: 120px;
          padding: 8px 20px;
          font-size: 14px;
          border: 1px solid ${theme.colors.grey};
          outline: none;
          &:focus {
            background-color: rgba(173, 216, 230, 0.2);
            box-shadow: 0 0 8px ${theme.colors.blueLight};
          }
        }
      }
    }
  }

  p {
    text-align: center;
    font-weight: 600;
    font-size: 1.8rem;
    margin-top: 15%;
  }
`;
