import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { useConfiguration } from '../../hooks/useConfiguration';
import { useParams } from 'react-router-dom';
import { actions } from '../../context/actions';
import { useAppContext } from '../../context';
import { IProduct } from '../../types';
import { contentSquarePageEvent, dynataAddToCart } from '../../helpers';
import { ModalTypes } from '../Modals/types';
import { GradientCircularProgress } from '../CircularProgress';
import { AddPositionEnum } from '../../types/config';
import { TextAndStepper } from './variants/TextAndStepper';
import { theme } from '../../theme';
import { useQuery } from '@apollo/client';
import { GET_LOCALIZED } from '../../queries';
export const AddToCartButton = ({
  type,
  product,
  url,
  handleButtonClick,
}: any) => {
  const [newAmount, setNewAmount] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const { storeId = '', harvestId = '' } = useParams();
  const { AddProductButton, LanguageVersion } = useConfiguration({
    storeId,
    harvestId,
  });
  
  const { data: translation } = useQuery(GET_LOCALIZED, {
    variables: {
      defaultLanguage: LanguageVersion,
    },
  });
  const { AddToCartButtonName } = product;

  const {
    dispatch,
    value: { cart },
  } = useAppContext();

  const handleAddToCartSubmit = () => {
    contentSquarePageEvent('addToCart');
    if (newAmount > 0) {
      dispatch({
        type: actions.ADD_TO_CART,
        payload: { ...product, newAmount },
      });
      dynataAddToCart(url + '_cartadd' + newAmount);
    }
    if (newAmount === 0) {
      dynataAddToCart(url + '_cartadd' + newAmount);
      dispatch({
        type: actions.REMOVE_FROM_CART,
        payload: { type: 'REMOVE_ALL', product },
      });
    }
  };
  useEffect(() => {
    const productInCart = cart?.find(
      (productInCart: IProduct) => product.id === productInCart?.id
    );

    setNewAmount(productInCart?.amount || 0);
  }, [cart, product]);

  const handleAddToCartChange = (type: string) => {
    setLoading(true);
    setTimeout(() => {
      if (type === 'plus') {
        contentSquarePageEvent('addToCartIncrease');
        dynataAddToCart(url + '_cartadd' + (newAmount + 1));
        dispatch({ type: actions.ADD_TO_CART, payload: { ...product } });
        dispatch({
          type: actions.TOGGLE_MODAL,
          payload: {
            isModal: true,
            modalType: ModalTypes.centerModalInformation,
            modalContent: {
              description: translation?.getLocalizationAmzWm?.AddedToCart || 'Added to cart',
            },
          },
        });
      }
      if (type === 'minus') {
        contentSquarePageEvent('addToCartDecrease');
        if (newAmount <= 0) {
          dynataAddToCart(url + '_cartadd');
        } else {
          dynataAddToCart(url + '_cartadd' + (newAmount - 1));
        }
        dispatch({
          type: actions.REMOVE_FROM_CART,
          payload: { product, type: 'REMOVE_ONE' },
        });
      }
      setLoading(false);
    }, 800);
  };

  return (
    <Wrapper>
      <AddButton onClick={handleButtonClick}>
        {!isLoading ? (
          <TextAndStepper
            newAmount={newAmount}
            AddProductButton={
              AddToCartButtonName ? AddToCartButtonName : AddProductButton
            }
            handleAddToCartSubmit={handleAddToCartSubmit}
            handleAddToCartChange={handleAddToCartChange}
            variant={'small'}
          />
        ) : (
          <GradientCircularProgress />
        )}
      </AddButton>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-top: 15px;
`;

export const AddButton = styled.div<{ AddPosition?: AddPositionEnum }>`
  position: relative;
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0;
  align-items: center;
  min-height: 34px;
  border-radius: 30px;
  border: none;
  background: ${theme.amazon.yellow};
  color: black;
  :disabled {
    border: 1px solid orange;
    background-color: ${theme.amazon.yellow};
    opacity: 0.3;
  }
`;
