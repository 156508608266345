import React from 'react';
import { ProductContainer, DescriptionContainer, LinkStyled } from './styles';
import { IProduct } from '../../types';
import { useConfiguration } from '../../hooks/useConfiguration';
import { useParams } from 'react-router-dom';
import { IAddProductButtonFunctionality } from '../../types/config';
import { Rating, Stack } from '@mui/material';
import { AddToCartButton } from '../AddToCartButton';
import { imgSrcByEnv } from '../../../config/imgSrcByEnv';
import { useQuery } from '@apollo/client';
import { GET_LOCALIZED } from '../../queries';

export const ProductCard = ({
  product,
  onClick,
  url,
  variant,
}: {
  product: IProduct;
  onClick: string;
  url: string;
  variant?: string;
}) => {
  const { storeId = '', harvestId = '' } = useParams();
  const { Currency, ProductCardBorderType, LanguageVersion } = useConfiguration({
    storeId,
    harvestId,
  });
  const { data: translation } = useQuery(GET_LOCALIZED, {
    variables: {
      defaultLanguage: LanguageVersion,
    },
  });
  const currency = Currency ? Currency : '$';

  const pricePerUnit =
    product.PricePerUnit && product.PricePerUnit !== '0'
      ? ' ' + product.PricePerUnit + ''
      : '';

  const price =
    product?.Price !== 0
      ? product?.Price?.toFixed(2)
      : product?.RegularPrice?.toFixed(2);
  const [priceIntegerPart, priceFractionalPart] = price.split('.');

  const threeDaysFromNow = new Date();
  threeDaysFromNow.setDate(threeDaysFromNow.getDate() + 3);

  const formatDate = threeDaysFromNow.toLocaleDateString(LanguageVersion?.replace('_','-'), {
    weekday: 'short',
    month: 'long',
    day: 'numeric',
  });
  return (
    <LinkStyled to={onClick}>
      <ProductContainer
        variant={variant ? variant : ''}
        ProductCardBorder={ProductCardBorderType}
      >
        <div>
          <img
            src={`${imgSrcByEnv()}${
              product?.Gallery && product?.Gallery[0]?.image?.fullpath
            }`}
            alt="gallery fullpath"
          />
        </div>
        <DescriptionContainer variant={variant}>
          {product.Sponsored && <p className="sponsored">{translation?.getLocalizationAmzWm?.sponsored || 'Sponsored'}</p>}
          {product.Name && <p className="name">{product.Name}</p>}
          {product.RRAmount > 0 && (
            <div className="rating">
              {variant === 'horizontal' && <span>{product.RROverall}</span>}
              <Stack spacing={1}>
                <Rating
                  name="half-rating-read"
                  value={product.RROverall}
                  precision={0.5}
                  readOnly
                />
              </Stack>
              <span>({product?.RRAmount})</span>
            </div>
          )}
          {variant === 'horizontal' && (
            <>
              {product.LabelText && (
                <p className="limitedTimeDeal">{product.LabelText}</p>
              )}
            </>
          )}
          {variant === 'horizontal' ? (
            <div className="priceContainer">
              {product.Price && product.Price !== 0 ? (
                <p className="price">
                  <sup>{currency}</sup>
                  {priceIntegerPart}
                  <sup>{priceFractionalPart}</sup>
                  <span className="oldPrice">
                  {translation?.getLocalizationAmzWm?.TypicalPrice || 'typical price:'} <s>${product.RegularPrice.toFixed(2)}</s>
                  </span>
                </p>
              ) : (
                <p className="price">
                  <sup>{currency}</sup>
                  {priceIntegerPart}
                  <sup>{priceFractionalPart}</sup>
                </p>
              )}
              {/* <span className="pricePerUnit">{pricePerUnit}</span> */}
            </div>
          ) : (
            <div className="priceContainer">
              <p className="simpleProduct">
                {currency}
                {product.Price.toFixed(2)}
              </p>
            </div>
          )}
          {variant === 'horizontal' && (
            <div className="delivery">
              <p>
              {translation?.getLocalizationAmzWm?.Delivery || 'Delivery'} <span>{formatDate}</span>
              </p>
            </div>
          )}
          <div className="promo">
            {product.PromoImage && (
              <img
                src={`${imgSrcByEnv()}${
                  product?.PromoImage && product?.PromoImage.fullpath
                }`}
                alt="promo information"
              />
            )}
            <p>{product.PromoText}</p>
          </div>
          <AddToCartButton
            type={IAddProductButtonFunctionality.textAndStepper}
            product={product}
            url={url}
          />
        </DescriptionContainer>
      </ProductContainer>
    </LinkStyled>
  );
};
