import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { IProduct } from '../../types';
interface CheckoutSectionProps {
  imgSrcByEnv: () => void;
  storeId: string;
  harvestId: string;
  product: IProduct;
}

export const ProductGalleryImage = ({
  imgSrcByEnv,
  product,
  storeId,
  harvestId,
}: CheckoutSectionProps) => {
  const navigate = useNavigate();

  const navigateToProduct = (id: string) =>
    navigate(`/${storeId}/${harvestId}/ips/${id}`);

  return (
    <ImageContainer>
      <ProductImage
        src={`${imgSrcByEnv()}${product.Gallery[0]?.image?.fullpath}`}
        alt="product logo"
        onClick={() => navigateToProduct(product.id)}
      />
    </ImageContainer>
  );
};

const ImageContainer = styled.div`
  position: relative;
  flex: 1 1 50%;
`;

const ProductImage = styled.img`
  width: 200px;
  height: 200px;
  object-fit: contain;
`;
