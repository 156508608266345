import useId from '@mui/material/utils/useId';
import { IAttributes, IProduct } from '../../types';
import styled from '@emotion/styled';
interface IAttributesSection {
  product: IProduct;
}

export const AttributesSection = ({ product }: IAttributesSection) => {
  const id = useId();

  if (!product || !product.Attribute || product.Attribute.length === 0) {
    return null;
  }
  return (
    <>
      {product?.Attribute.map(
        (attribute: IAttributes, index: number) =>
          attribute.AttrName !== '' &&
          attribute.AttrValue !== '' && (
            <AttributeContainer key={`${id}_${index}`}>
              {attribute.AttrName}: {attribute.AttrValue}
            </AttributeContainer>
          )
      )}
    </>
  );
};

const AttributeContainer = styled.div`
  margin-top: 10px;
  font-size: 14px;
  font-weight: 600;
`;
