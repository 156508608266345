import React from 'react';
import { IAttributes, IProduct } from '../../types';
import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAppContext } from '../../context';
import { actions } from '../../context/actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBox,
  faCar,
  faChevronDown,
  faChevronUp,
  faInfoCircle,
  faMapMarkerAlt,
  faShareAlt,
  faTruck,
} from '@fortawesome/free-solid-svg-icons';
import { faHeart as farHeart } from '@fortawesome/free-regular-svg-icons';
import { faHeart as fasHeart } from '@fortawesome/free-solid-svg-icons';

import {
  ButtonStyled,
  Content,
  GalleryContainer,
  InfoLine,
  InfoSection,
  PdpCardStyled,
  QuantityTypography,
  ShippingMethodStyled,
  Title,
} from './styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled as MuiStyled } from '@mui/material/styles';
import { imgSrcByEnv } from '../../../config/imgSrcByEnv';
import { useConfiguration } from '../../hooks/useConfiguration';
import { normalizeNameForUrl } from '../../helpers';
import { VariantsFilter } from '../Filters/VariantsFilter';
import { METHODS } from 'http';
import { contentSquarePageEvent, dynataAddToCart } from '../../helpers';
import { useQuery } from '@apollo/client';
import { GET_LOCALIZED, GET_PRODUCT_CATEGORY_BY_TERM } from '../../queries';
import { PdpGallery } from '../PdpGallery';
import { ModalTypes } from '../Modals/types';
import { LoadingDotsAnimation } from '../LoadingDotsAnimation';
import { AdvBanner } from '../AdvBanner';
import { PdpGallerySlider } from '../PdpGallerySlider';

export const PdpCard = ({ product }: { product: IProduct }) => {
  const [newAmount, setNewAmount] = React.useState(1);
  const [prevKey, setPrevKey] = React.useState('');
  const [loadingState, setLoadingState] = React.useState({
    addToCart: false,
    buyNow: false,
  });
  const location = useLocation();

  const navigate = useNavigate();
  const { storeId = '', categoryId, harvestId = '', productId } = useParams();
  const {
    dispatch,
    value: { cart, termId },
  } = useAppContext();
  const { Currency, ShipmentMethod, AddProductButton } = useConfiguration({
    storeId,
    harvestId,
  });

  const { LanguageVersion } = useConfiguration({ storeId, harvestId });
  const { data: translation } = useQuery(GET_LOCALIZED, {
    variables: {
      defaultLanguage: LanguageVersion,
    },
  });
  const { data, loading } = useQuery(
    GET_PRODUCT_CATEGORY_BY_TERM({
      termId: termId?.split('__')[0],
    })
  );
  const edges = data?.getEPogTermListing?.edges;
  let TermBannerImage,
    TermBannerProducts,
    TermBannerSponsoredBy,
    TermBannerText;
  if (termId && edges?.length > 0) {
    TermBannerImage = edges[0]?.node?.TermBannerImage;
    TermBannerProducts = edges[0]?.node?.TermBannerProducts;
    TermBannerSponsoredBy = edges[0]?.node?.TermBannerSponsoredBy;
    TermBannerText = edges[0]?.node?.TermBannerText;
  }

  // let href = `${window.location.pathname}__${product.Name}`;
  let href = window.location.pathname;

  const price = product.Price !== 0 ? product.Price : product.RegularPrice;
  const currency = Currency ? Currency : '$';
  const threeDaysFromNow = new Date();
  threeDaysFromNow.setDate(threeDaysFromNow.getDate() + 3);

  const formatDate = threeDaysFromNow.toLocaleDateString('en-US', {
    month: 'long',
    day: 'numeric',
  });
  const twoDaysFromNow = new Date();
  twoDaysFromNow.setDate(twoDaysFromNow.getDate() + 2);

  const dayOfWeek = twoDaysFromNow.toLocaleDateString('en-US', {
    weekday: 'long',
  });
  const [shippingMethodSelected, setShippingMethodSelected] = React.useState(
    {}
  );
  const [isFilled, setIsFilled] = React.useState(false);

  const [openDropdownIndices, setOpenDropdownIndices] = React.useState<
    Number[]
  >([]);
  const pricePerUnit =
    product.PricePerUnit && product.PricePerUnit !== '0'
      ? ' (' + product.PricePerUnit + ')'
      : '';

  const shipmentMethods = React.useMemo(() => {
    return (
      ShipmentMethod?.map((item) => {
        const getIcon = (type: string) => {
          type = type.toLowerCase();

          if (type === 'shipping') {
            return <FontAwesomeIcon icon={faTruck} />;
          }
          if (type === 'pickup') {
            return <FontAwesomeIcon icon={faCar} />;
          }
          if (type === 'delivery') {
            return <FontAwesomeIcon icon={faBox} />;
          }
        };

        const getDesc = (type: string) => {
          type = type.toLowerCase();

          if (type === 'shipping') {
            return 'Get it in 2-3 days, Free for Plus, From $5.50 for Club Members';
          }
          if (type === 'pickup') {
            return 'Get it in 2 hours, Free for Plus, $4 for Club Members';
          }
          if (type === 'delivery') {
            return 'Get it in 3 hours, $8 for Plus, $12 for Club Members';
          }
        };

        return {
          icon: getIcon(item.Name),
          name: item.Name,
          description: getDesc(item.Name),
        };
      }) || []
    );
  }, [ShipmentMethod]);

  React.useEffect(() => {
    setShippingMethodSelected(shipmentMethods?.[0]?.name.toLowerCase());
  }, [shipmentMethods]);

  const productInCart = cart.find(
    (productInCart: IProduct) => product.id === productInCart?.id
  );
  const handleQuantityChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setNewAmount(event.target.value as number);
  };

  React.useEffect(() => {
    setNewAmount(productInCart?.amount || 1);
  }, [cart, product, productInCart?.amount]);

  React.useEffect(() => {
    if (product?.Attribute && product?.Attribute.length > 0) {
      const allIndices = product?.Attribute.map((_, index) => index);
      setOpenDropdownIndices(allIndices);
    }
  }, [product?.Attribute]);

  const toggleDropdown = (index: number) => {
    setOpenDropdownIndices((currentIndices) =>
      currentIndices.includes(index)
        ? currentIndices.filter((i) => i !== index)
        : [...currentIndices, index]
    );
  };
  const handleBuyNow = () => {
    setLoadingState((prevState) => ({ ...prevState, buyNow: true }));
    setTimeout(() => {
      dispatch({
        type: actions.ADD_TO_CART,
        payload: {
          ...product,
          newAmount,
          shippingMethod: shippingMethodSelected,
        },
      });
      navigate(`/${storeId}/${harvestId}/cart`);
      dynataAddToCart(href + '_cartadd' + newAmount);
      setLoadingState((prevState) => ({ ...prevState, buyNow: false }));
    }, 800);
  };
  React.useEffect(() => {
    // if pathname is the same, but with '_cartadd...' postfix, the prevKey is the same
    if (
      location.state?.prevPath.split('_cartadd')[0] ===
      location.pathname.split('_cartadd')[0]
    ) {
      setPrevKey(location.state.prevKey);
    }
  }, [location]);
  React.useEffect(() => {
    setPrevKey(location.key);
  }, []);

  const handleAddToCart = () => {
    const navState = { prevPath: location.pathname, prevKey: prevKey };
    setLoadingState((prevState) => ({ ...prevState, addToCart: true }));
    setTimeout(() => {
      if (newAmount > 0 && href?.indexOf('_cartadd') === -1) {
        dispatch(
          {
            type: actions.ADD_TO_CART,
            payload: {
              ...product,
              newAmount,
              shippingMethod: shippingMethodSelected,
            },
          },
          navigate(href + '_cartadd' + newAmount, {
            replace: true,
            state: navState,
          })
        );
      } else {
        dispatch(
          {
            type: actions.ADD_TO_CART,
            payload: {
              ...product,
              newAmount,
              shippingMethod: shippingMethodSelected,
            },
          },
          contentSquarePageEvent('addToCart'),
          navigate(href.replace(/_cartadd\d?/, '') + '_cartadd' + newAmount, {
            replace: true,
            state: navState,
          })
        );
      }

      if (newAmount === 0) {
        dispatch({
          type: actions.REMOVE_FROM_CART,
          payload: { product },
        });
      }
      setLoadingState((prevState) => ({ ...prevState, addToCart: false }));
    }, 800);
    dispatch({
      type: actions.TOGGLE_MODAL,
      payload: {
        isModal: true,
        modalType: ModalTypes.centerModalInformation,
        modalContent: {
          description: 'Added to cart',
          isActive: true,
        },
      },
    });
  };

  const handleToggleFill = () => setIsFilled(!isFilled);
  const openQuantitySelectModal = () => {
    dispatch({
      type: actions.TOGGLE_MODAL,
      payload: {
        isModal: true,
        modalType: ModalTypes.quantitySelect,
        modalContent: {
          newAmount,
          handleChange: handleQuantityChange,
          maxValue: 21,
        },
      },
    });
  };
  return (
    <PdpCardStyled>
      {TermBannerProducts && (
        <AdvBanner
          TermBannerImage={TermBannerImage}
          TermBannerProducts={TermBannerProducts}
          TermBannerSponsoredBy={TermBannerSponsoredBy}
          TermBannerText={TermBannerText}
          Currency={currency}
        />
      )}
      {product.Sponsored && (
        <div className="sponsored-container">
          <span className="sponsored">
            Sponsored <FontAwesomeIcon icon={faInfoCircle} />
          </span>
        </div>
      )}
      <div className="brand-line">
        <p>{product.Brand}</p>
        {product.RRAmount !== 0 ? (
          <div className="rating">
            {product.RROverall && (
              <Stack spacing={1}>
                <Rating
                  name="half-rating-read"
                  defaultValue={product.RROverall}
                  precision={0.5}
                  readOnly
                />
              </Stack>
            )}
            {product.RRAmount && <span>{product.RRAmount}</span>}
          </div>
        ) : (
          <div className="rating">
            {
              <Stack spacing={1}>
                <Rating
                  name="half-rating-read"
                  defaultValue={0}
                  precision={0.5}
                  readOnly
                />
              </Stack>
            }
            <span>(0)</span>
          </div>
        )}
      </div>
      <p>{product.Name}</p>
      <GalleryContainer>
        <div className="share-icon-container">
          <FontAwesomeIcon icon={faShareAlt} />
        </div>
        <PdpGallery product={product} />
        {/* <PdpGallerySlider product={product} /> */}
        <div className="heart-icon-container">
          <FontAwesomeIcon
            icon={isFilled ? fasHeart : farHeart}
            onClick={handleToggleFill}
          />
        </div>
      </GalleryContainer>
      <hr></hr>
      {product?.Attribute[0].AttrName &&
        product?.Attribute[0].AttrValue !== 'value' && (
          <div className="variants-container">
            {product?.Attribute.map((attribute, index) => {
              const hasVariants =
                product.Variants && product.Variants.length > 0;
              return (
                <div
                  className="variants-inner-container"
                  key={`${attribute.AttrName}-${index}`}
                >
                  <div
                    className="selectContainer"
                    onClick={
                      hasVariants ? () => toggleDropdown(index) : undefined
                    }
                    style={{
                      cursor: hasVariants ? 'pointer' : 'default',
                    }}
                  >
                    <span>
                      <span>
                        {hasVariants ? (
                          openDropdownIndices.includes(index) ? (
                            <div>
                              {attribute.AttrName} -{' '}
                              <span className="attribute-value-open">
                                {attribute.AttrValue}
                              </span>
                            </div>
                          ) : (
                            <>
                              {attribute.AttrName}
                              <div className="attribute-value-closed">
                                {attribute.AttrValue}
                              </div>
                            </>
                          )
                        ) : (
                          <>
                            {attribute.AttrName}
                            <div className="attribute-value-closed">
                              {attribute.AttrValue}
                            </div>
                          </>
                        )}
                      </span>
                      {hasVariants && (
                        <FontAwesomeIcon
                          className={`faIcon ${
                            openDropdownIndices.includes(index)
                              ? 'rotate-up'
                              : 'rotate-down'
                          }`}
                          icon={faChevronDown}
                        />
                      )}
                    </span>
                  </div>
                  {openDropdownIndices.includes(index) && hasVariants && (
                    <div className="variants" key={index}>
                      {product?.Variants?.map(
                        (variant: IProduct, variant_index: number) => {
                          if (
                            variant?.Attribute[index]?.AttrName &&
                            variant?.Attribute[index]?.AttrValue !== 'value' &&
                            product?.Attribute[index].AttrValue !==
                              variant?.Attribute[index]?.AttrValue
                          ) {
                            const brand = variant?.Brand ? variant?.Brand : '';
                            return (
                              <div
                                className={
                                  product?.id === variant?.id ? 'active' : ''
                                }
                                key={`${variant.id}-${variant_index}`}
                                onClick={() =>
                                  navigate(
                                    categoryId
                                      ? `/${storeId}/${harvestId}/ip/${categoryId}/${
                                          variant?.id
                                        }__${brand}__${normalizeNameForUrl(
                                          variant?.Name
                                        )}_${variant?.RegularPrice.toFixed(2)}`
                                      : `/${storeId}/${harvestId}/ips/${
                                          variant?.id
                                        }__${brand}__${normalizeNameForUrl(
                                          variant?.Name
                                        )}_${variant?.RegularPrice.toFixed(2)}`
                                  )
                                }
                              >
                                <CustomizedoTooltip title={variant?.Name}>
                                  {variant?.Attribute[index]?.AttrValue !=
                                  'value' ? (
                                    <p>
                                      {variant?.Attribute[index]?.AttrName}:{' '}
                                      {variant?.Attribute[index]?.AttrValue}
                                    </p>
                                  ) : (
                                    <p>test</p>
                                  )}
                                </CustomizedoTooltip>
                                {variant?.RegularPrice && (
                                  <p className="variant-price">
                                    {currency}
                                    {variant?.RegularPrice.toFixed(2)}
                                  </p>
                                )}
                              </div>
                            );
                          } else if (
                            variant?.Attribute[index]?.AttrName &&
                            variant?.Attribute[index]?.AttrValue !== 'value' &&
                            product?.id === variant?.id
                          ) {
                            const brand = variant?.Brand ? variant?.Brand : '';
                            return (
                              <div
                                className={
                                  product?.id === variant?.id ? 'active' : ''
                                }
                                key={variant_index}
                                onClick={() =>
                                  navigate(
                                    categoryId
                                      ? `/${storeId}/${harvestId}/ip/${categoryId}/${
                                          variant?.id
                                        }__${brand}__${normalizeNameForUrl(
                                          variant?.Name
                                        )}_${variant?.RegularPrice.toFixed(2)}`
                                      : `/${storeId}/${harvestId}/ips/${
                                          variant?.id
                                        }__${brand}__$${normalizeNameForUrl(
                                          variant?.Name
                                        )}_${variant?.RegularPrice.toFixed(2)}`
                                  )
                                }
                              >
                                <CustomizedoTooltip title={variant?.Name}>
                                  {variant?.Attribute[index]?.AttrValue !==
                                  'value' ? (
                                    <p>
                                      {variant?.Attribute[index]?.AttrName}:{' '}
                                      {variant?.Attribute[index]?.AttrValue}
                                    </p>
                                  ) : (
                                    <p></p>
                                  )}
                                </CustomizedoTooltip>
                                {variant?.RegularPrice &&
                                  variant?.Attribute[index]?.AttrValue !=
                                    'value' && (
                                    <p className="variant-price">
                                      {currency}
                                      {variant?.RegularPrice.toFixed(2)}
                                    </p>
                                  )}
                              </div>
                            );
                          } else {
                            return '';
                          }
                        }
                      )}
                    </div>
                  )}
                  <hr />
                </div>
              );
            })}
          </div>
        )}
      {product.LabelText && (
        <p className="limitedTimeDeal">{product.LabelText}</p>
      )}
      {price && (
        <div className="price">
          {product.Price !== 0 ? (
            <div>
              {currency}
              {product.Price.toFixed(2)}{' '}
              <span className="oldPrice">
                {currency}
                {product.RegularPrice.toFixed(2)}
              </span>
            </div>
          ) : (
            <div>
              {currency}
              {product.RegularPrice.toFixed(2)}
            </div>
          )}
          <span className="pricePerUnit">{pricePerUnit}</span>
        </div>
      )}
      {shipmentMethods.length ? (
        <div className="shipping-methods">
          <p>{translation?.getLocalization.ShippingMethod}:</p>
          <div className="container">
            {shipmentMethods.map((method, key) => (
              <ShippingMethod
                key={key}
                isActive={shippingMethodSelected === method.name.toLowerCase()}
                method={method}
                onClick={() =>
                  setShippingMethodSelected(method.name.toLowerCase())
                }
              />
            ))}
          </div>
          <div className="description">
            {shipmentMethods.map((method, key) => (
              <div key={key}>
                {shippingMethodSelected === method.name.toLowerCase()
                  ? method.description
                  : ''}
              </div>
            ))}
          </div>
        </div>
      ) : (
        ''
      )}
      <div className="cart">
        <p>{translation?.getLocalizationAmzWm?.InStock || "In stock"}</p>
        <div className="cart-manipulation">
          <div onClick={openQuantitySelectModal}>
            <QuantityTypography>{translation?.getLocalizationAmzWm?.Quantity || "Quantity"}: {newAmount}</QuantityTypography>
            <FontAwesomeIcon icon={faChevronDown} />
          </div>
        </div>
        <ButtonStyled
          variant="contained"
          onClick={handleAddToCart}
          disabled={loadingState.addToCart}
        >
          {loadingState.addToCart ? (
            <LoadingDotsAnimation />
          ) : productInCart?.amount > 0 ? (
            translation?.getLocalization.UpdateCart
          ) : (
            translation?.getLocalization.AddToCart
          )}
        </ButtonStyled>

        <ButtonStyled
          variant="contained"
          onClick={handleBuyNow}
          disabled={loadingState.buyNow}
        >
          {loadingState.buyNow ? <LoadingDotsAnimation /> : (translation?.getLocalizationAmzWm?.BuyNow || 'Buy Now')}
        </ButtonStyled>
      </div>
      <InfoSection>
        <InfoLine>
          <Title>{translation?.getLocalizationAmzWm?.ShopFrom || "Shop from"}</Title>
          <Content>Amazon</Content>
        </InfoLine>
        <InfoLine>
          <Title>{translation?.getLocalization?.SoldBy || "Sold by"}</Title>
          <Content className="link">ZWD-Direct</Content>
        </InfoLine>
        <InfoLine>
          <Title>{translation?.getLocalizationAmzWm?.Returns || "Returns"}</Title>
          <Content className="link">
            {translation?.getLocalizationAmzWm?.EligibleForReturn || "Eligible for Return, Refund or Replacement within 30 days of receipt"}
          </Content>
        </InfoLine>
        <InfoLine>
          <Title>{translation?.getLocalizationAmzWm?.Payment || "Payment"}</Title>
          <Content className="link">{translation?.getLocalizationAmzWm?.SecureTransaction || "Secure transaction"}</Content>
        </InfoLine>
      </InfoSection>
      <hr />
    </PdpCardStyled>
  );
};

const ShippingMethod = ({
  method,
  isActive,
  onClick,
}: {
  method: { name: string; icon: any; description: any };
  isActive?: boolean;
  onClick: () => void;
}) => {
  return (
    <ShippingMethodStyled onClick={onClick} isActive={isActive}>
      {method.icon} {method.name}
    </ShippingMethodStyled>
  );
};

const CustomizedoTooltip = MuiStyled(
  ({ className, title, icon, children }: any) => (
    <Tooltip title={title} classes={{ popper: className }}>
      {children}
    </Tooltip>
  )
)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 12,
  },
}));
