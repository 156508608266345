import React from 'react';

import { IProduct } from '../../../types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import FormGroup from '@mui/material/FormGroup';

import { FilterModalCommonStyles } from '../commonStyles';
import { SpecialOffersStyled } from './styles';
import { useSpecialOffersFilter } from './useSpecialOffersFilter';

import { useParams } from 'react-router-dom';
import { useConfiguration } from '../../../hooks/useConfiguration';
import { GET_LOCALIZED } from '../../../queries';
import { useQuery } from '@apollo/client';
import { Chip } from '../../Chip';
import { theme } from '../../../theme/theme';

export const SpecialOffersFilterModal = ({
  productsBeforeFiltering,
  buttonsOff,
}: any) => {
  const { handleCheckboxes, checked } = useSpecialOffersFilter({
    productsBeforeFiltering,
  });

  const { storeId = '', termId, harvestId = '' } = useParams();

  const { LanguageVersion } = useConfiguration({ storeId, harvestId });

  const { data: translation } = useQuery(GET_LOCALIZED, {
    variables: {
      defaultLanguage: LanguageVersion,
    },
  });

  return (
    <FilterModalCommonStyles buttonsOff={buttonsOff}>
      <SpecialOffersStyled>
        <div>
          <div className="checkboxes">
            <FormGroup>
              {productsBeforeFiltering ? (
                productsBeforeFiltering.map(
                  (product: IProduct, index: number) => (
                    <Chip
                      key={index}
                      title={product.PromoType}
                      onClick={() => handleCheckboxes(product.PromoType)}
                      isExpand={checked[product.PromoType]}
                      addinitonalIcon={
                        checked[product.PromoType] && (
                          <FontAwesomeIcon icon={faTimes} />
                        )
                      }
                      fontColor={
                        checked[product.PromoType]
                          ? `${theme.colors.primary}`
                          : ''
                      }
                      background={
                        checked[product.PromoType]
                          ? `${theme.colors.buttonActive}`
                          : `${theme.colors.white}`
                      }
                    />
                  )
                )
              ) : (
                <div></div>
              )}
            </FormGroup>
          </div>
        </div>
      </SpecialOffersStyled>
    </FilterModalCommonStyles>
  );
};
