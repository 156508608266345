import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { ProductCardBorderEnum } from '../../types/config';
import { theme } from '../../theme';
export const ProductContainer = styled.div<{
  ProductCardBorder?: ProductCardBorderEnum;
  variant?: string;
}>`
  padding: 16px;
  max-width: 430px;
  max-height: 560px;
  width: ${({ variant }) => (variant === 'vertical' ? ' 49vw' : 'auto')};
  display: ${({ variant }) => (variant === 'vertical' ? 'block' : 'flex')};
  align-items: center;
  box-shadow: ${({ ProductCardBorder, variant }) =>
    ProductCardBorder === ProductCardBorderEnum.shadow &&
    variant === 'horizontal' &&
    '0px 0px 6px rgba(0, 0, 0, 0.1)'};
  border: ${({ ProductCardBorder }) =>
    ProductCardBorder === ProductCardBorderEnum.border &&
    '1px solid rgba(0, 0, 0, 0.1)'};
  border-radius: 10px;
  & > div:nth-of-type(1) {
    width: ${({ variant }) => variant === 'horizontal' && '40%'};
  }
  & > div:nth-of-type(1) > img {
    height: 150px;
    width: 100%;
    object-fit: contain;
    display: block;
    margin: 0 auto;
  }
`;

export const LinkStyled = styled(Link)`
  text-decoration: none;
  color: ${theme.amazon.black};
`;

export const DescriptionContainer = styled.div<{
  variant?: string;
}>`
  width: ${({ variant }) => variant === 'horizontal' && '70%'};
  margin-left: ${({ variant }) => variant === 'horizontal' && '10px'};
  p {
    font-family: 'Montserrat';
  }

  .sponsored {
    color: ${theme.amazon.greyDark};
  }

  .name {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    display: -webkit-box;
    overflow: hidden;
    margin-top: 10px;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: ${({ variant }) =>
    variant === 'vertical' ? '2' : '3'};
    color: ${({ variant }) => variant === 'vertical' && theme.amazon.primary};
  }
  .rating {
    margin-top: 5px;
    display: flex;
    align-items: center;
    font-size: 12px;
    & > span:nth-of-type(1) {
      color: ${theme.amazon.primary};
      font-weight: 600;
      margin-right: 3px;
    }
    & > span:nth-of-type(2) {
      color: ${theme.amazon.greyDark};
    }
  }
  .limitedTimeDeal {
    margin-top: 5px;
    display: inline-block;
    padding: 6px;
    font-weight: 600;
    border-radius: 2px;
    background-color: ${theme.amazon.red};
    color: ${theme.amazon.white};
  }
  .priceContainer {
    display: flex;
    align-items: baseline;
    flex-wrap: nowrap;
    width: 100%;
    margin-top: 5px;
    line-height: 17px;

    .price {
      font-size: 24px;
      .oldPrice {
        font-size: 12px;
        color: gray;
      }
    }
    .price > sup {
      font-weight: 500;
      font-size: 12px;
      margin-right: 2px;
    }
    .pricePerUnit {
      margin-left: 2px;
      font-size: 12px;
      color: ${theme.amazon.greyDark};
    }
    .simpleProduct {
      font-size: 22px;
      color: ${theme.amazon.red};
    }
  }
  .delivery {
    margin-top: 10px;
    font-weight: 500;
    & > p:nth-of-type(1) {
      font-size: 12px;
      & > span {
        font-weight: 700;
      }
    }
    & > p:nth-of-type(2) {
      margin-top: 10px;
      color: ${theme.amazon.greyDark};
    }
  }
  .promo {
    margin-top: 10px;
    & > img {
      width: 100%;
    }
  }
`;
