import styled from '@emotion/styled';

export const Header = styled.h1`
  max-width: 743px;
  line-height: 29px;
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 24px;
  color: #000000;
  margin-top: 40px;
  margin-bottom: 24px;
  margin-left: 3px;
`;

export const PopularSectionsStyled = styled('div', {
  shouldForwardProp(propName) {
    return propName !== 'isLessThanSix' && propName !== 'CarouselWidth';
  },
}) <{ isLessThanSix: boolean | undefined; CarouselWidth?: string }>`
  background: #ffffff;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);


  @media (max-width: 768px) {
    padding: 15px;
  }
  & > div {
    display: flex;
    align-items: center;
  }

  .header {
    h1 {
      font-weight: 600;
      font-size: 20px;
      line-height: 29px;
      color: #000000;
      margin-bottom: 15px;
    }

  }

  .popular-item {
    display: flex;
    justify-content: ${({ isLessThanSix, CarouselWidth }) =>
    isLessThanSix && CarouselWidth === 'Full'
      ? 'flex-start'
      : 'space-between'}
      space-between;
    align-items: start;
    gap: 15px;
    overflow-x: scroll;
    flex-wrap: ${({ CarouselWidth }) => CarouselWidth !== 'Full' && 'wrap'};

    & > div {
      position: relative;
      cursor: pointer;
      margin-right: ${({ isLessThanSix }) => (isLessThanSix ? '50px' : '0')};
    }

    & > div > img {
      border-radius: 50%;
      width: ${({ CarouselWidth }) =>
    CarouselWidth !== 'Full' ? '60px' : '160px'};
      height: auto;
      object-fit: contain;
    }

    & > div > p {
      position: relative;
      top: -15px;
      background-color: #ffffff;
      width: ${({ CarouselWidth }) =>
    CarouselWidth !== 'Full' ? '60px' : '160px'};
      text-align: center;
      padding-top: 10px;
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
    }
  }
`;
