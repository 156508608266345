import React, { useState } from 'react';
import { IProduct } from '../../types';
import { useParams } from 'react-router-dom';
import styled from '@emotion/styled';
import { normalizeNameForUrl } from '../../helpers';
import { Link } from 'react-router-dom';
import { imgSrcByEnv } from '../../../config/imgSrcByEnv';
import { theme } from '../../theme';
import { useConfiguration } from '../../hooks/useConfiguration';
import { useQuery } from '@apollo/client';
import { GET_LOCALIZED } from '../../queries';

export const PopularProductItem = ({
  products,
}: {
  products: IProduct[];
  className?: string;
}) => {
  const { categoryId, storeId='', harvestId='' } = useParams();
  const [loadMore, setLoadMore] = useState(false);
  const { LanguageVersion } = useConfiguration({
    storeId,
    harvestId,
  });
  const { data: translation } = useQuery(GET_LOCALIZED, {
    variables: {
      defaultLanguage: LanguageVersion,
    },
  });
  let viewProduct = products;
  let viewMoreProduct;
  if (products.length > 9) {
    viewProduct = products.slice(0, 9);
    viewMoreProduct = products.slice(9);
  }

  return (
    products && (
      <>
        <WrapperContainer>
          {viewProduct.map(
            ({
              Price,
              RegularPrice,
              Brand,
              Gallery,
              id,
              Name,
              LabelText,
              URLPromoCode,
            }: IProduct) => {
              const price = Price !== 0 ? Price : RegularPrice;
              const brand = Brand ? Brand : '';
              const imageSrc = `${imgSrcByEnv()}${
                Gallery && Gallery[0]?.image?.fullpath
              }`;

              let promoCode;
              if (URLPromoCode != null) {
                promoCode = '__promoCode-' + URLPromoCode;
              } else {
                promoCode = '__promoCode-';
              }
              return (
                <ProductContainer key={id}>
                  <Link
                    to={
                      categoryId
                        ? `/${storeId}/${harvestId}/ip/${categoryId}/${id}__${brand}__${normalizeNameForUrl(
                            Name
                          )}_${price}_${promoCode}`
                        : `/${storeId}/${harvestId}/ips/${id}__${brand}__${normalizeNameForUrl(
                            Name
                          )}_${price}_${promoCode}`
                    }
                  >
                    <ProductImage src={imageSrc} alt="gallery fullpath" />
                    {LabelText ? (
                      <div className="customLabel">{LabelText}</div>
                    ) : (
                      ''
                    )}
                  </Link>
                </ProductContainer>
              );
            }
          )}
          {viewMoreProduct &&
            loadMore &&
            viewMoreProduct.map((product: IProduct) => {
              const price =
                product.Price !== 0 ? product.Price : product.RegularPrice;
              const brand = product?.Brand ? product?.Brand : '';

              return (
                <ProductContainer key={product.id}>
                  <Link
                    to={
                      categoryId
                        ? `/${storeId}/${harvestId}/ip/${categoryId}/${
                            product?.id
                          }__${brand}__${normalizeNameForUrl(
                            product?.Name
                          )}_${price}`
                        : `/${storeId}/${harvestId}/ips/${
                            product?.id
                          }__${brand}__${normalizeNameForUrl(
                            product?.Name
                          )}_${price}`
                    }
                  >
                    <ProductImage
                      src={`${imgSrcByEnv()}${
                        product?.Gallery && product?.Gallery[0]?.image?.fullpath
                      }`}
                      alt="gallery fullpath"
                    />
                    {product.LabelText ? (
                      <div className="customLabel">{product.LabelText}</div>
                    ) : (
                      ''
                    )}
                  </Link>
                </ProductContainer>
              );
            })}
        </WrapperContainer>
        {!loadMore && products.length > 9 && (
          <LoadMoreButtonStyled onClick={() => setLoadMore(true)}>
            {translation?.getLocalizationAmzWm?.LoadMore || 'Load More'}
          </LoadMoreButtonStyled>
        )}
      </>
    )
  );
};
const WrapperContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  justify-content: space-between;
  padding-right: 8px;
`;

const ProductContainer = styled.div`
  width: 30%;
  height: 30vw;
`;

const ProductImage = styled.img`
  border-radius: 5px;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
  background-position: center;
  object-fit: contain;
  padding: 8px;
`;
const LoadMoreButtonStyled = styled.button`
  margin-top: 10px;
  border: none;
  background-color: transparent;
  color: ${theme.amazon.primary};
  text-decoration: underline;
  padding: 0;
`;
