import React from 'react';
import { useConfiguration } from '../../hooks/useConfiguration';
import { Carousel } from '../../components/Carousel';
import { PopularSections } from '../../components/PopularSections';
import { ProductImagesSlider } from '../../components/ProductImagesSlider';
import { Code } from 'react-content-loader';
import { useParams } from 'react-router-dom';
import { PopularProducts } from '../../components/PopularProducts';
import { useMainRoute } from './useMainRoute';
import { MainRouteStyled } from './styles';
import { useQuery } from '@apollo/client';
import { GET_LOCALIZED } from '../../queries';
import hero from '../../assets/images/hero.png';
import { imgSrcByEnv } from '../../../config/imgSrcByEnv';
import perfume1 from '../../assets/images/perfume_1.avif';
import perfume2 from '../../assets/images/perfume_2.avif';
import perfume3 from '../../assets/images/perfume_3.avif';
import perfume4 from '../../assets/images/perfume_4.jpg';
import amazon1 from '../../assets/images/amazon_1.jpeg';
import amazon2 from '../../assets/images/amazon_2.jpg';
import amazon3 from '../../assets/images/amazon_3.jpeg';
import pets1 from '../../assets/images/pets_1.jpg';
import pets2 from '../../assets/images/pets_2.jpg';
import pets3 from '../../assets/images/pets_3.jpg';
import pets4 from '../../assets/images/pets_4.jpg';
import category1 from '../../assets/images/category_1.jpeg';
import category2 from '../../assets/images/category_2.jpeg';
import category3 from '../../assets/images/category_3.jpeg';
import category4 from '../../assets/images/category_4.jpeg';
import category5 from '../../assets/images/category_5.jpeg';
import category6 from '../../assets/images/category_6.jpeg';
import category7 from '../../assets/images/category_7.jpeg';
import styled from '@emotion/styled';

export const MainRoute = () => {
  const {
    shuffledProducts,
    categories,
  }: { shuffledProducts: any; categories: any } = useMainRoute();
  const { storeId = '', harvestId = '' } = useParams();

  const coffeMachines = [perfume1, perfume2, perfume3, perfume4];
  const productCategories = [
    { name: 'Continue shopping', image: category1 },
    { name: 'Offers for you on clothes', image: category2 },
    { name: 'Electronics up to $50', image: category3 },
    { name: 'Household equipment', image: category4 },
    { name: 'Female fashion', image: category5 },
    { name: 'Tools and renovation', image: category6 },
    { name: 'Children toys and teddies', image: category7 },
  ];
  const pets = [pets2, pets3, pets4];

  const {
    loading,
    CarouselWidth,
    CarouselItems,
    SecondLineElementWidth,
    SecondLineProductsExpositionName,
    SecondLineSliderName,
    SecondLineSliderProducts,
    SecondLineExpositionProducts,
    LanguageVersion,
  } = useConfiguration({ storeId, harvestId });

  const { data: translation } = useQuery(GET_LOCALIZED, {
    variables: {
      defaultLanguage: LanguageVersion,
    },
  });

  if (loading) {
    return (
      <div style={{ marginTop: 24 }}>
        <Code />
      </div>
    );
  } else {
    return (
      <div style={{ height: '100%' }}>
        <div
          style={{
            position: 'relative',
            width: '100%',
            height: '452px',
            background: 'linear-gradient(to bottom, #e57373, lightgrey)',
            marginTop: '65px',
          }}
        >
          <img
            src={hero}
            alt="hero"
            style={{
              width: '100%',
              height: '300px',
              objectFit: 'cover',
            }}
          />
          <p
            style={{
              position: 'absolute',
              top: '32%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: 1,
              color: 'black',
              textAlign: 'center',
              fontSize: '18px',
              fontWeight: 700,
              overflowX: 'hidden',
            }}
          >
            Beauty Essentials
          </p>

          <div
            style={{
              overflowX: 'auto',
              maxWidth: '100%',
              whiteSpace: 'nowrap',
              padding: '0px 0px 0px 8px',
              position: 'absolute',
              top: 255,
              right: 0,
            }}
          >
            {productCategories.map((category: any, index: number) => (
              <CategoryItem
                key={index}
                style={{
                  display: 'inline-block',
                  marginRight: '8px',
                  borderRadius: '4px 4px 4px 4px',
                  width: '140px',
                  height: '160px',
                  backgroundColor: '#fafafafa',
                  flexWrap: 'wrap',
                }}
              >
                <p
                  style={{
                    height: '50px',
                    display: 'flex',
                    padding: '4px 8px',
                    alignItems: 'center',
                    fontSize: '16px',
                    fontWeight: 600,
                    width: '100%',
                    margin: 0,
                    whiteSpace: 'normal',
                  }}
                >
                  {category.name}
                </p>

                <img
                  src={category.image}
                  alt={'product category'}
                  style={{
                    width: '140px',
                    height: '140px',
                    objectFit: 'cover',
                    borderRadius: '0px 0px 4px 4px',
                  }}
                />
              </CategoryItem>
            ))}
          </div>

          <div
            style={{
              marginTop: '150px',
              display: 'flex',
              overflowX: 'auto',
              flexWrap: 'wrap',
              padding: '0px 16px',
              justifyContent: 'space-between',
              backgroundColor: 'white',
            }}
          >
            <p
              style={{ padding: '16px 0px', fontSize: '16px', fontWeight: 700 }}
            >
              Unveil your radiance
            </p>
            <div
              style={{
                display: 'flex',
                overflowX: 'auto',
                flexWrap: 'wrap',
                justifyContent: 'space-between',
              }}
            >
              {coffeMachines.map((machine) => (
                <img
                  key={machine}
                  src={machine}
                  alt="coffe machine model"
                  style={{
                    width: '48%',
                    marginBottom: '8px',
                    borderRadius: '8px',
                  }}
                />
              ))}
            </div>
          </div>

          <div style={{ marginBottom: '16px' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                padding: '0px 16px',
              }}
            >
              <p
                style={{
                  display: 'flex',
                  padding: '16px 0px',
                  fontSize: '16px',
                  fontWeight: 700,
                }}
              >
                Popular tablets
              </p>
              <img
                src={amazon1}
                alt="amazon"
                style={{ width: '100%', borderRadius: '8px' }}
              />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                padding: '0px 16px',
              }}
            >
              <p
                style={{
                  display: 'flex',
                  padding: '16px 0px',
                  fontSize: '16px',
                  fontWeight: 700,
                }}
              >
                Amazon products
              </p>
              <img
                src={amazon2}
                alt="amazon"
                style={{ width: '100%', borderRadius: '8px' }}
              />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                padding: '0px 16px',
              }}
            >
              <p
                style={{
                  display: 'flex',
                  padding: '16px 0px',
                  fontSize: '16px',
                  fontWeight: 700,
                }}
              >
                Best video games
              </p>
              <img
                src={amazon3}
                alt="amazon"
                style={{ width: '100%', borderRadius: '8px' }}
              />
            </div>
          </div>

          <div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                padding: '0px 16px',
              }}
            >
              <p
                style={{
                  display: 'flex',
                  padding: '16px 0px',
                  fontSize: '16px',
                  fontWeight: 700,
                }}
              >
                Pets section
              </p>
              <img
                src={pets1}
                alt="amazon"
                style={{ width: '100%', height: '250px', borderRadius: '8px' }}
              />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  marginTop: '8px',
                }}
              >
                {pets.map((pet) => (
                  <img
                    key={pet}
                    src={pet}
                    alt="pet"
                    style={{ width: '32%', borderRadius: '8px' }}
                  />
                ))}
              </div>
            </div>
          </div>

          {/* <div className="product-image-slider-container">
            <ProductImagesSlider
              title={SecondLineSliderName}
              products={SecondLineSliderProducts}
              BackgroundImage="/files/generic/amazonExample.jpg"
              ContainerHeight="500px"
              className="hero"
            ></ProductImagesSlider>
          </div> */}

          {/* <div className="product-exposition-container">
            <ProductImagesSlider
              title={SecondLineProductsExpositionName}
              products={SecondLineExpositionProducts}
              className="swiper2"
            ></ProductImagesSlider>
          </div> */}

          {CarouselWidth === 'Full' && categories?.length !== 0 && (
            <div className="popular-sections-container">
              <PopularSections
                CarouselWidth={CarouselWidth}
                categories={categories}
              ></PopularSections>
            </div>
          )}

          <div>
            <div className="simmilar">
              <PopularProducts
                products={shuffledProducts.shuffledRecommended}
                title={translation?.getLocalization.PopularProducts}
              />
            </div>

            <div className="simmilar" style={{ marginBottom: '80px' }}>
              <PopularProducts
                products={shuffledProducts.shuffledSimilar}
                title={translation?.getLocalization.PopularIn}
              />
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginTop: '16px',
              justifyContent: 'space-between',
              alignItems: 'center',
              backgroundColor: 'lightgray',
              padding: '6px',
              width: '100%',
            }}
          >
            <div
              style={{
                width: '25%',
                height: '1px',
                backgroundColor: 'grey',
              }}
            />
            <p style={{ color: '#252525' }}>
              {' '}
              This is the end. Search for more.
            </p>
            <div
              style={{ width: '25%', height: '1px', backgroundColor: 'grey' }}
            />
          </div>
        </div>
      </div>
    );
  }
};

const CategoryItem = styled.div`
  display: inline-block;
  margin-right: 8px;
  border-radius: 4px;
  width: 140px;
  height: 160px;
  background-color: #fafafafa;
  flex-wrap: wrap;
  ::-webkit-scrollbar {
    display: none;
  }
`;
