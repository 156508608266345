import React, { useState, useEffect } from 'react';
import { useAppContext } from '../../../context';
import { actions } from '../../../context/actions';
import { IProduct } from '../../../types';

interface CheckedState {
  [key: string]: boolean;
}

export const useBrandsFilter = ({
  productsBeforeFiltering,
}: {
  productsBeforeFiltering: IProduct[];
}) => {
  const {
    value: { searchFilters },
    dispatch,
  } = useAppContext();

  const brandFilter = searchFilters.find(
    (filter: any) => filter.name === 'Brand'
  );

  const createInitialCheckedState = (): CheckedState =>
    productsBeforeFiltering.reduce(
      (obj: CheckedState, product: IProduct) => ({
        ...obj,
        [product.Brand]: brandFilter
          ? !!brandFilter.brands[product.Brand]
          : false,
      }),
      {}
    );

  const [searchBar, setSearchBar] = useState('');
  const [checked, setChecked] = useState<CheckedState>(
    createInitialCheckedState()
  );

  useEffect(() => {
    if (brandFilter) {
      setChecked(brandFilter.brands as CheckedState);
    } else {
      setChecked(createInitialCheckedState());
    }
  }, [brandFilter, productsBeforeFiltering]);

  const handleCheckboxes = (brandName: string) => {
    const newCheckedState = {
      ...checked,
      [brandName]: !checked[brandName],
    };

    setChecked(newCheckedState);

    dispatch({
      type: actions.ADD_SEARCH_FILTER,
      payload: { name: 'Brand', brands: newCheckedState },
    });
  };

  const handleClear = () => {
    const resetState = createInitialCheckedState();
    setChecked(resetState);
    dispatch({
      type: actions.REMOVE_SEARCH_FILTER,
      payload: { name: 'Brand' },
    });
  };

  return {
    searchBar,
    setSearchBar,
    handleClear,
    handleCheckboxes,
    checked,
  };
};
