import styled from '@emotion/styled';
import { theme } from '../../theme/index';

export const StockAvailabilitySection = ({content}: {content?:string}) => (
  <StyledStockAvailabilitySection>{content || "In stock"}</StyledStockAvailabilitySection>
);

const StyledStockAvailabilitySection = styled.div`
  color: ${theme.amazon.primary};
  margin-top: 10px;
  font-size: 14px;
  font-weight: 600;
`;
