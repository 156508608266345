import React from 'react';
import { IProduct } from '../../types';
import 'swiper/css';
import 'swiper/css/navigation';
import { ProductCard } from '../ProductCard';
import { useParams } from 'react-router-dom';
import styled from '@emotion/styled';
import { normalizeNameForUrl } from '../../helpers';
export const SimilarProducts = ({
  products,
  className,
}: {
  products: IProduct[];
  className?: string;
}) => {
  const { categoryId, storeId, harvestId } = useParams();
  return (
    <div
      className={className}
      style={{ display: 'flex', justifyContent: 'center' }}
    >
      {products && (
        <ProductListStyled>
          {products.map((product: IProduct) => {
            const { id, Name, Price, RegularPrice, Brand, URLPromoCode } =
              product;

            const price = Price !== 0 ? Price : RegularPrice;
            const brand = Brand ? Brand : '';
            let promoCode;
            if (URLPromoCode != null) {
              promoCode = '__promoCode-' + URLPromoCode;
            } else {
              promoCode = '__promoCode-';
            }

            return (
              <>
                <ProductCard
                  variant="vertical"
                  key={product.id + product.Name}
                  product={product}
                  url={
                    categoryId
                      ? `/${storeId}/${harvestId}/ip/${categoryId}/${id}__${brand}__${normalizeNameForUrl(
                          Name
                        )}_${price}_${promoCode}`
                      : `/${storeId}/${harvestId}/ips/${id}__${brand}__${normalizeNameForUrl(
                          Name
                        )}_${price}_${promoCode}`
                  }
                  onClick={
                    categoryId
                      ? `/${storeId}/${harvestId}/ip/${categoryId}/${id}__${Brand}__${normalizeNameForUrl(
                          Name
                        )}_${Price}_${promoCode}`
                      : `/${storeId}/${harvestId}/ips/${id}__${Brand}__${normalizeNameForUrl(
                          Name
                        )}_${Price}_${promoCode}`
                  }
                />
              </>
            );
          })}
        </ProductListStyled>
      )}
    </div>
  );
};

const ProductListStyled = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: start;
  gap: 15px;
  overflow-x: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
`;
