import styled from '@emotion/styled';
import { theme } from '../../theme';

export const ProductDescriptionStyled = styled.div`
  max-width: 450px;
  & > h3:nth-of-type(1),
  & > h3:nth-of-type(2),
  & > div,
  & > p {
    margin: 0 10px;
    font-size: 14px;
    line-height: 17px;
    color: ${theme.amazon.black};
  }
  & > h3:nth-of-type(1) {
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 10px;
  }
  & > div {
    p {
      & > img {
        width: 100%;
        height: auto;
      }
    }
    & table {
      display: block;
      width: 100%;
      overflow-x: auto;
      border-collapse: collapse;
      & th,
      & td {
        border: 1px solid ${theme.amazon.grey};
        padding: 8px;
        text-align: left;
        & h3 {
          font-size: 16px;
          & a {
            color: ${theme.amazon.primary};
            text-decoration: none;
          }
        }
      }
      & img {
        max-width: 400px;
        height: auto;
        display: block;
      }
    }
  }
.swiper {
  min-height:100px;
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;

  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    & > div:nth-child(2) {
      position: absolute;
      left: 5%;
      max-width: 50%;
      padding: 10px;
      background: #000000bd;
      color:white;
      * {
        margin:unset;
        color:white;
      }
    }
  }

  @media screen and (max-width:768px){
    .swiper-slide {
      text-align: center;
      display: flex;
      flex-direction:column;
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
      & > div:nth-child(n){
        position: static;
        max-width: unset;
        padding: 10px;
        background: none;
        color:black;
      }
    }
    .swiper-pagination{
      position:static;
    }
  }
  
}

`