import { useAppContext } from '../../context';
import { IProduct } from '../../types';
import { useCartHook } from './useCartHook';
import { CartRouteStyled } from './styles';
import { useNavigate, useParams } from 'react-router-dom';
import { useConfiguration } from '../../hooks/useConfiguration';
import { imgSrcByEnv } from '../../../config/imgSrcByEnv';
import { useQuery } from '@apollo/client';
import { GET_LOCALIZED, GET_PRODUCT_CATEGORY_BY_TERM } from '../../queries';
import { theme } from '../../theme/index';
import { LimitedOfferSection } from './LimitedOfferSection';
import { PriceSection } from './PriceSection';
import { ProductDataSection } from './ProductDataSection';
import { StockAvailabilitySection } from './StockAvailabilitySection';
import { AttributesSection } from './AttributesSection';
import { ProductGalleryImage } from './ImageSection';
import { AdvBanner } from '../../components/AdvBanner';
import styled from '@emotion/styled';
import { FaRegTrashCan } from 'react-icons/fa6';
import { useCallback } from 'react';

export const CartRoute = () => {
  let href = window.location.pathname;

  const {
    totalCartValue,
    totalItemsInCart,
    checkoutMessage,
    checkout,
    handleCart,
  } = useCartHook();
  const { storeId = '', harvestId = '' } = useParams();
  const navigate = useNavigate();
  const { LanguageVersion, Currency } = useConfiguration({
    storeId,
    harvestId,
  });
  const currency = Currency ? Currency : '$';
  const { data: translation } = useQuery(GET_LOCALIZED, {
    variables: {
      defaultLanguage: LanguageVersion,
    },
  });
  const {
    value: { cart, termId },
  } = useAppContext();
  const { data } = useQuery(
    GET_PRODUCT_CATEGORY_BY_TERM({
      termId: termId?.split('__')[0],
    })
  );

  const handleAddToCart = useCallback(
    (product) => {
      handleCart('add', product);
    },
    [handleCart]
  );

  const handleRemoveFromCart = useCallback(
    (product) => {
      handleCart('remove', product);
    },
    [handleCart]
  );

  const handleRemoveAllFromCart = useCallback(
    (product) => {
      handleCart('removeAll', product);
    },
    [handleCart]
  );

  const edges = data?.getEPogTermListing?.edges;

  let TermBannerImage,
    TermBannerProducts,
    TermBannerSponsoredBy,
    TermBannerText;
  if (termId && edges?.length > 0) {
    TermBannerImage = edges[0]?.node?.TermBannerImage;
    TermBannerProducts = edges[0]?.node?.TermBannerProducts;
    TermBannerSponsoredBy = edges[0]?.node?.TermBannerSponsoredBy;
    TermBannerText = edges[0]?.node?.TermBannerText;
  }
  if (checkoutMessage !== '' && href.indexOf('checkout') === -1) {
    navigate(href + '/checkout');
  }

  const totalPrice: string = String(totalCartValue);
  const dotIndex: number = totalPrice.indexOf('.');
  const totalDollar: string = totalPrice.slice(0, dotIndex);
  const totalCents: string = totalPrice.slice(dotIndex + 1);

  if (totalItemsInCart && totalItemsInCart > 0) {
    return (
      <>
        {TermBannerProducts && (
          <div style={{ marginTop: '65px' }}>
            <AdvBanner
              TermBannerImage={TermBannerImage}
              TermBannerProducts={TermBannerProducts}
              TermBannerSponsoredBy={TermBannerSponsoredBy}
              TermBannerText={TermBannerText}
              Currency={currency}
            />
          </div>
        )}
        <CheckoutSectionWrapper>
          <TotalLine>
            <TotalAmount>
              <span>{translation?.getLocalization.Subtotal}</span>
              <PriceWrapper>
                <Price>{totalDollar}</Price>
                <Dollar>{totalCents}</Dollar>
              </PriceWrapper>
            </TotalAmount>
            <CheckoutButton onClick={() => checkout('checkout', cart)}>
              {translation?.getLocalizationAmzWm?.ProceedToCheckout || 'Proceed to checkout'} ({totalItemsInCart}{' '}
              {translation?.getLocalization?.Items || 'items'})
            </CheckoutButton>
          </TotalLine>
        </CheckoutSectionWrapper>
        <div>
          <div>
            {cart?.map((product: IProduct, index: number) => {
              const productInCart = cart.find(
                (productInCart: IProduct) => product.id === productInCart?.id
              ) as IProduct;
              const isAmount = productInCart.amount === 0 ? false : true;

              return (
                <ComponentWrapper key={index}>
                  <ProductWrapper>
                    <>
                      <ProductContainer>
                        <ProductGalleryImage
                          imgSrcByEnv={imgSrcByEnv}
                          product={product}
                          storeId={storeId}
                          harvestId={harvestId}
                        />
                        <ProductDataContainer>
                          <ProductDataSection
                            product={product}
                            storeId={storeId}
                            harvestId={harvestId}
                            soldCountText={translation?.getLocalizationAmzWm?.BoughtPastMonth}
                          />
                          <LimitedOfferSection product={product} />
                          <PriceSection product={product} />
                          <StockAvailabilitySection content={translation?.getLocalizationAmzWm?.InStock}/>
                          <AttributesSection product={product} />
                        </ProductDataContainer>
                      </ProductContainer>
                      <StepperComponentWrapper>
                        <StepperContentWrapper>
                          <QuantityButton
                            onClick={() => handleRemoveFromCart(product)}
                          >
                            {product.amount === 1 ? (
                              <FaRegTrashCan
                                size={'20px'}
                                color={theme.amazon.black}
                              />
                            ) : (
                              <p>-</p>
                            )}
                          </QuantityButton>
                          <span>{isAmount ? productInCart.amount : 0}</span>
                          <QuantityButton
                            onClick={() => handleAddToCart(product)}
                          >
                            <p>+</p>
                          </QuantityButton>
                        </StepperContentWrapper>
                        <FirstButtonRow>
                          <DeleteButton
                            onClick={() => handleRemoveAllFromCart(product)}
                          >
                            {translation?.getLocalizationAmzWm?.Delete || 'Delete'}
                          </DeleteButton>
                        </FirstButtonRow>
                      </StepperComponentWrapper>
                    </>
                  </ProductWrapper>
                </ComponentWrapper>
              );
            })}
          </div>
        </div>
      </>
    );
  } else {
    if (checkoutMessage !== '') {
      return (
        <CartRouteStyled>
          <div className="empty">{translation?.getLocalization?.CheckoutMessage || 'Checkout finished.'}</div>
        </CartRouteStyled>
      );
    } else {
      return (
        <CartRouteStyled>
          <div className="empty">
            {translation?.getLocalization.CartIsEmpty}
          </div>
        </CartRouteStyled>
      );
    }
  }
};

const ComponentWrapper = styled.div`
  &:last-child {
    margin-bottom: 40px;
  }
`;

const ProductWrapper = styled.div`
  margin-bottom: 16px;
  background-color: '#fafafa';
  padding: 8px 0px;
  height: 280px;
`;

const ProductContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0px 8px;
`;

const ProductDataContainer = styled.div`
  margin-left: 10px;
  flex-direction: column;
  justify-content: space-between;
`;

const CheckoutButton = styled.button`
  color: ${theme.amazon.black};
  background-color: ${theme.amazon.yellow};
  padding: 16px;
  width: 100%;
  margin-top: 32px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  font-family: 'Montserrat';
  border: none;
  text-decoration: none;
`;

const CheckoutSectionWrapper = styled.div`
  padding-right: 8px;
  padding-left: 8px;
  margin-bottom: 16px;
  margin-top: 80px;
`;

const TotalLine = styled.div`
  width: 100%;
  border-bottom: 1px solid ${theme.amazon.grey};
`;

const TotalAmount = styled.div`
  font-size: 16px;
  font-weight: 600;
  font-family: 'Montserrat';
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 16px;

  span {
    font-size: 20px;

    &:first-of-type {
      margin-right: 10px;
    }
  }
`;

const PriceWrapper = styled.div`
  display: flex;
  flex-direction: row;
  vertical-align: top;
`;

const Dollar = styled.sup`
  font-size: 12px;
  line-height: 1;
`;

const Price = styled.p`
  font-size: 20px;
  font-weight: bold;
  line-height: 1;
`;

const StepperComponentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 8px;
`;

const StepperContentWrapper = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  border-style: solid;
  width: 50%;
  margin-left: 8px;

  span {
    border: 2px solid ${theme.amazon.greyLight};
    color: ${theme.amazon.primary};
    font-size: 20px;
    height: 100%;
    width: 80px;
    padding: 12px;
    font-weight: 600;
  }

  p {
    font-size: 30px;
    color: ${theme.amazon.black};
  }
`;

const Button = styled.button`
  background-color: ${theme.amazon.white};
  border: 2px solid ${theme.amazon.greyLight};
  color: ${theme.amazon.black};
  padding: 0px 8px;
  border-radius: 12px;
  font-size: 14px;
  margin-right: 8px;
  text-decoration: none;
  font-family: Montserrat;
  font-weight: 600;
  height: 48px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

const QuantityButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid ${theme.amazon.greyLight};
  background-color: ${theme.amazon.white};
  padding: 10px;
  width: 52px;
  height: 48px;
  border-radius: 14px 0px 0px 14px;
  border-right-width: 0px;
  background: linear-gradient(
    to top,
    ${theme.amazon.greyLight},
    ${theme.amazon.white}
  );

  &::first-of-type {
    border-radius: 14px 0 0 14px;
  }

  &:last-child {
    border-radius: 0px 14px 14px 0px;
    border-right-width: 2px;
    border-left-width: 0px;
  }
`;

const FirstButtonRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const DeleteButton = styled(Button)`
  margin-left: -5px;
`;
