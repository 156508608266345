import React, { useCallback, useEffect } from 'react';

import styled from '@emotion/styled';

import { useParams } from 'react-router-dom';

import Dialog from '@mui/material/Dialog';
import { ModalTypes } from './types';
import { useAppContext } from '../../context';
import { FiltersMenu } from '../FiltersMenu';
import { actions } from '../../context/actions';
import { useConfiguration } from '../../hooks/useConfiguration';
import { BrandsFilterModal } from '../Filters/BrandsFilter';
import { CategoriesFilterModal } from '../Filters/CategoriesFilter';
import { VariantsFilter } from '../Filters/VariantsFilter';
import { SpecialOffersFilterModal } from '../Filters/SpecialOffersFilter';
import { PriceRangeFilter } from '../Filters/PriceRangeFilter';
import { Sorting } from '../Filters/Sorting';
import { RatingFilter } from '../Filters/RatingFilter';
import QuantitySelectModal from '../QuantitySelect';
import { CenterModalInformation } from '../CenterInformation';
import { TransitionCollapse } from '../Collapse';
export const ModalComponent = () => {
  const {
    value: { isModal, modalType, modalContent },
    dispatch,
  } = useAppContext();
  const { storeId = '', harvestId = '' } = useParams();
  const { FilterPosition } = useConfiguration({ storeId, harvestId });

  const handleClose = useCallback(() => {
    dispatch({
      type: actions.TOGGLE_MODAL,
      payload: { isModal: false, modalContent: undefined },
    });
  }, [dispatch]);

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;
    if (modalType === ModalTypes.centerModalInformation && isModal) {
      timer = setTimeout(handleClose, 1500);
    }
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [modalType, isModal, handleClose]);

  const modalSelector = () => {
    switch (modalType as ModalTypes) {
      case ModalTypes.filtersMenu:
        return (
          <FiltersMenu
            productsBeforeFiltering={modalContent.productsBeforeFiltering}
            isSlide={isModal}
          />
        );

      case ModalTypes.priceRange:
        return (
          <PriceRangeFilter
            productsBeforeFiltering={modalContent.productsBeforeFiltering}
          />
        );

      case ModalTypes.brands:
        return (
          <BrandsFilterModal
            productsBeforeFiltering={modalContent.productsBeforeFiltering}
          />
        );

      case ModalTypes.sorting:
        return <Sorting />;

      case ModalTypes.rating:
        return <RatingFilter />;

      case ModalTypes.variants:
        return <VariantsFilter />;

      case ModalTypes.specialOffers:
        return (
          <SpecialOffersFilterModal
            productsBeforeFiltering={modalContent.productsBeforeFiltering}
          />
        );

      case ModalTypes.categories:
        return (
          <CategoriesFilterModal
            productsBeforeFiltering={modalContent.productsBeforeFiltering}
          />
        );
      case ModalTypes.quantitySelect:
        return (
          <QuantitySelectModal
            newAmount={modalContent.newAmount}
            handleChange={modalContent.handleChange}
            maxValue={modalContent.maxValue}
            handleClose={handleClose}
          />
        );
      case ModalTypes.centerModalInformation:
        return (
          <CenterModalInformation
            description={modalContent.description}
            isActive={modalContent.isActive}
          />
        );

      default:
        return false;
    }
  };

  return (
    <DialogStyled
      open={isModal}
      onClose={handleClose}
      modalType={modalType}
      FilterPosition={FilterPosition}
    >
      {modalSelector()}
    </DialogStyled>
  );
};

const DialogStyled = styled(Dialog, {
  shouldForwardProp: (prop) =>
    prop !== 'modalType' && prop !== 'FilterPosition' && prop !== 'isOpen',
})<{
  modalType: ModalTypes;
  FilterPosition?: string;
  open: boolean;
}>`
  .MuiDialog-container {
    display: flex;
    justify-content: ${({ modalType, FilterPosition }) => {
      if (modalType === ModalTypes.filtersMenu) {
        return FilterPosition === 'Left' ? 'flex-start' : 'flex-end';
      }
      return 'center';
    }};
  }

  .MuiDialog-paper {
    width: ${({ modalType }) =>
      modalType === ModalTypes.filtersMenu ? '100vw' : 'auto'};
    margin: ${({ modalType }) =>
      modalType === ModalTypes.filtersMenu ? '60px 0 0 0' : undefined};
    z-index: ${({ modalType }) =>
      modalType === ModalTypes.filtersMenu ? '1100' : undefined};
  }
  .MuiBackdrop-root {
    background: ${({ modalType }) =>
      modalType === ModalTypes.filtersMenu
        ? 'transparent'
        : modalType === ModalTypes.centerModalInformation
        ? 'rgba(255, 255, 255, 0.5)'
        : 'rgba(0, 0, 0, 0.5)'};
  }
`;
